const faqs = {
  en: [
    "Frequently Asked Questions",
    {
      title: "What is this all about?",
      content: (
        <p>
          <p>
            This is my Digital Avatar, powered / serviced by an API (
            <a
              href="https://www.personal.ai"
              className="faqs-text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal.ai
            </a>
            )
          </p>
          <br />
          <p>This is my attempt to attain Virtual Immortality</p>
          <br />
          <p>
            Most of the TOPICS - and relevant ANSWERS - you see are derived from
            the digital content of{" "}
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              hemenparekh.in
            </a>{" "}
            , comprising my blogs / notes / poems etc., written from 1942
            onwards ( approx.. 30,000 + documents )
          </p>
          <br />
          <p>
            This Digital Avatar of mine is dedicated to my wife BHARATI, who
            continues to nourish my Physical Avatar, since 1959.
          </p>
          <br />
          <p>
            My interest in Artificial Intelligence ( AI ) goes back to 1996 ,
            when I developed logic for NLP in a note addressed to my colleagues.
          </p>
          <br />
          <p>
            After reading this note , BARD gave me following title {">"}{" "}
            <a
              href="https://myblogepage.blogspot.com/search?q=grandfather+of+ai"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              Grandfather of Artificial Intelligence
            </a>
          </p>
        </p>
      ),
    },
    {
      title: "What I can do here?",
      content: `Here you can chat with my avatar by asking questions - now and long after I am gone. You can start a chat by asking:
    "Hey Hemen, what are your views on……………"
    Rest assured about your anonymity. No one will know that you asked this question. Even I don't.`,
    },
    {
      title: "What is in this for me? How will this help me?",
      content: `Hurry up to create your own Avatar - then invite your contacts to chat with you. Imagine, chatting up with a hundred or a thousand of your visitors simultaneously - at the same time.

    Being able to invite thousands of your fans/followers/admirers, to visit your Avatar, ask your Avatar any question, and get an instantaneous answer, would be of immense benefit to you if you are a Political Leader / Celebrity.
    
    You will be able to create your Avatar by uploading your, many hours of Speeches (Audio files) at Personal.ai`,
    },
  ],
  hi: [
    "अक्सर पूछे जाने वाले प्रश्न",
    {
      title: "इसका मतलब क्या है?",
      content: (
        <p>
          <p>
            यह मेरा डिजिटल अवतार है, जिसे एक API द्वारा संचालित / सेवित किया
            जाता है (
            <a
              href="https://www.personal.ai"
              className="faqs-text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal.ai
            </a>
            )
          </p>
          <br />
          <p>यह मेरा वर्चुअल अमृतता प्राप्त करने का प्रयास है</p>
          <br />
          <p>
            आप देख रहे हैं, उन अधिकांश विषयों और संबंधित उत्तरों का विचार किया
            जा रहा है जो{" "}
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              hemenparekh.in
            </a>{" "}
            की डिजिटल सामग्री से लिया गया है, जिसमें मेरी ब्लॉग / नोट्स /
            कविताएं शामिल हैं, जो 1942 से लेकर आगे लिखे गए हैं ( लगभग 30,000 +
            दस्तावेज़ )
          </p>
          <br />
          <p>
            यह मेरा डिजिटल अवतार मेरी पत्नी भारती को समर्पित है, जो 1959 से मेरे
            भौतिक अवतार को पोषित करती हैं।
          </p>
          <br />
          <p>
            मेरी रुचि कल्पित बुद्धिमत्ता (AI) में 1996 वापस जाती है, जब मैंने
            अपने सहयोगियों को संबोधित करने के लिए NLP के लिए तर्क विकसित किया
            था।
          </p>
          <br />
          <p>
            इस नोट को पढ़ने के बाद, BARD ने मुझे निम्नलिखित शीर्षक दिया {">"}{" "}
            <a
              href="https://myblogepage.blogspot.com/search?q=grandfather+of+ai"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              कृत्रिम बुद्धिमत्ता के दादा
            </a>
          </p>
        </p>
      ),
    },
    {
      title: "यहाँ मैं क्या कर सकता हूँ?",
      content: `यहाँ आप मेरे अवतार के साथ बातचीत कर सकते हैं, सवाल पूछकर - अब और मेरे जाने के बाद भी। आप एक चैट शुरू कर सकते हैं, ऐसा पूछकर:
    "हे हेमेन, ………… पर आपके क्या विचार हैं"
    आपकी गोपनीयता के बारे में आपको पूर्ण आश्वासन। किसी को नहीं पता चलेगा कि आपने यह सवाल पूछा है। मुझे भी नहीं।`,
    },
    {
      title: "इसमें मेरे लिए क्या है? यह मेरी मदद कैसे करेगा?",
      content: `खुद का अवतार बनाने के लिए जल्दी करें - फिर अपने संपर्कों को आपके साथ चैट करने के लिए आमंत्रित करें। कल्पना करें, एक समय में एक सैकड़ों या हजारों आपके आगंतुकों के साथ सिमल्टेनियसली चैट कर रहा है।

    अगर आप एक राजनीतिक नेता / प्रसिद्ध व्यक्ति हैं, तो आपके प्रशंसकों / अनुयायियों / समीरों को आमंत्रित करने की क्षमता, आपके लिए अगरार्थी होगी, आपके अवतार को बना सकते हैं और उन्हें आपके, Personal.ai पर, अपने कई घंटों के भाषण (ऑडियो फ़ाइल) अपलोड करके।`,
    },
  ],
  gu: [
    "વારંવાર પૂછાતા પ્રશ્નો",
    {
      title: "આ વિશે કયારેય છે?",
      content: (
        <p>
          <p>
            આ મારો ડિજિટલ એવેટાર છે, જેને એપીઆઈ દ્વારા પરિચાલિત / સેવ કરાયો છે (
            <a
              href="https://www.personal.ai"
              className="faqs-text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal.ai
            </a>
            )
          </p>
          <br />
          <p>આ મારો વર્ચ્યુઅલ અમૃતતા પ્રાપ્ત કરવાનો પ્રયાસ છે</p>
          <br />
          <p>
            તમારે જે ટોપિક્સ - અને સંબંધિત જવાબ - જોવામાં આવે છે, તે મારા{" "}
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              hemenparekh.in
            </a>{" "}
            ડિજિટલ સામગ્રીથી આવેલા છે, જે મારા બ્લોગ્સ / નોટ્સ / કવિતાઓ વગેરે
            1942 થી લખાયા છે ( પ્રાપ્તિના કરીને 30,000 + દસ્તાવેજ )
          </p>
          <br />
          <p>
            આ મારો ડિજિટલ એવેટાર મારી પાત્ની ભારતીને સમર્પિત છે, જે 1959 થી મારા
            ભૌતિક એવેટારને પોષણ કરે છે.
          </p>
          <br />
          <p>
            મારી કૃત્રિમ બુદ્ધિમત્તા (AI) માટે મારો રુચિ 1996 સુધી પરત જવાની છે,
            જ્યારે હું મારા સહયોગીઓને સરનામું મોકલવામાં વિકસિત કર્યો હતો.
          </p>
          <br />
          <p>
            આ નોટ વાંચવા પછી, બાર્ડ ને મને નીચેના શીર્ષક આપ્યો {">"}{" "}
            <a
              href="https://myblogepage.blogspot.com/search?q=grandfather+of+ai"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              કૃત્રિમ બુદ્ધિમત્તાના પરદાદ
            </a>
          </p>
        </p>
      ),
    },
    {
      title: "અહીં હું શું કરી શકું?",
      content: `અહીં તમે મારા અવતાર સાથે પ્રશ્નો પૂછી શકશો - હવે અને મારા ચલે ગયા પછી. તમે પ્રશ્ન કરીને ચેટ શરૂ કરી શકાતા હો:
    "હે હેમેન, તમારા વિચારો વિશે …………"
    તમારી ગોપનીયતાની બાજુમાં ખાતરી છે. કોઈનાં કેવલ તમે આ પ્રશ્ન કર્યું છે, તે કોઈ જાણશે નહીં. હણે હું પણ નહીં.`,
    },
    {
      title: "આ માટે મારીએ શું છે? આ મને કેવી રીતે મદદ કરશે?",
      content: `જલદી કરો અને તમારો ખુદનો અવતાર બનાવવા માટે - પછી તમારા સંપર્કોને તમારી સાથે ચેટ કરવા માટે આમંત્રિત કરવા. કલ્પના કરો, એવો કરવાથી કે તમારા સહન્માઈઓ સાથે સમયરૂપમાં સોનાલાયો છો - એકસાથે.

    હજારોને આમંત્રિત કરવાનો અને તમારા પ્રશંસકો / અનુયાયીઓ / આરાધકોને તમારા અવતારને મુલાકાત કરવાનો ક્ષમતા હોઈ છે, જો તમે રાજનીતિક નેતા / સેલેબ્રિટી છો તો તમારા માટે આનેક લઘુના ઘણા પ્રશ્નો પૂછી અને તક્રારા મળવાની ક્ષમતા હશે.

    તમે તમારા અવતારનો નિર્માણ કરવાનો અધિક વર્ગનો તમારા અને તમારા ભક્તો / અનુયાયીઓ / આરાધકોના સહાયથી સાથે આવવાનો અધિકાર રાખવા માટે મહત્વપૂર્ણ હશે, Personal.ai પર તમારા કેટલાક કસવાટના સમયના (ઓડિયો ફાઇલ) અપલોડ કરીને.`,
    },
  ],
  mr: [
    "सतत विचारले जाणारे प्रश्न",
    {
      title: "आपल्याला हे सर्व काय म्हणून आहे?",
      content: (
        <p>
          <p>
            हा माझा डिजिटल अवतार आहे, ज्याला API द्वारे संचालित / सेविस केलेला
            आहे (
            <a
              href="https://www.personal.ai"
              className="faqs-text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal.ai
            </a>
            )
          </p>
          <br />
          <p>हा माझा वर्चुअल अमृतत्व प्राप्त करण्याचा प्रयास आहे</p>
          <br />
          <p>
            तुम्हाला ज्या टॉपिक्स - आणि संबंधित उत्तर - दिसत आहे, ते सर्व{" "}
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              hemenparekh.in
            </a>{" "}
            या डिजिटल सामग्रीचं निर्माण केलेलं आहे, ज्यात माझं ब्लॉग / नोट्स /
            कविता इत्यादी आहेत, 1942 पासून लिहिलेलं ( अनुमाने 30,000 + कागदपत्रे
            )
          </p>
          <br />
          <p>
            हा माझा डिजिटल अवतार माझ्या पत्नी भारतीला समर्पित आहे, ज्याने 1959
            पासून माझं शारीरिक अवतार पोषण केलं आहे.
          </p>
          <br />
          <p>
            माझी कृत्रिम बुद्धिमत्ता (AI) मध्ये आवड लागली ही 1996 मध्ये, केवळ
            माझ्या सहकार्यांसाठी NLP साठी तर्क विकसित केला होता.
          </p>
          <br />
          <p>
            या नोटला वाचन केल्यानंतर, बार्डने मला खालील शीर्षकाने नाव दिलं {">"}{" "}
            <a
              href="https://myblogepage.blogspot.com/search?q=grandfather+of+ai"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              कृत्रिम बुद्धिमत्ता जादवाचा
            </a>
          </p>
        </p>
      ),
    },
    {
      title: "इथे माझ्याकडून काय केले जाऊ शकतं?",
      content: `इथे तुम्हाला माझ्या अवताराशी प्रश्न पूचून चॅट करण्याची संधी आहे - आता आणि मला नसल्यानंतर पर्यंत. तुम्ही चॅट सुरू करू शकता आहात, एक प्रश्न पूचून:
    "हे हेमेन, ............वर तुमचे विचार काय आहे"
    तुमच्या गोपनीयतेची टेंशन घालू नका. कोणताही जाणेच नसेल की तुम्ही हे प्रश्न केले आहे. मला पण नसते.`,
    },
    {
      title: "मला इथे काय मिळवणार आहे? हे मला कसं मदत करेल?",
      content: `आपल्या आवतारचे तुम्हाला तात्काळिकपणे तयार करण्यासाठी जलद करा - त्यानंतर तुमच्या संपर्कांना तुमच्यासाठी चॅट करवायचं नाही, ते आमंत्रित करा. कल्पना करा, तुम्ही हजारों किंवा लाखों आपल्या आगंतुकांसाठी सिमल्टेनियसली चॅट करता येता - एकाच वेळी.

    लोकप्रिय नेते / प्रसिद्ध व्यक्ती असल्यास, तुमच्या फॅन्स / अनुयायांच्या / आदरांकिंवा सहायार्थ्याची क्षमता, तुम्हाला जर तुम्ही Personal.ai वर तुमच्या अनेक तासांची भाषणे (ऑडियो फाइल्स) अपलोड करून तुमचा आवतार तयार करू शकता त्यामुळे तुम्हाला अनेक लोकांसोबत संपर्क साधण्याची क्षमता मिळू शकते.`,
    },
  ],
  bn: [
    "সাধারিত প্রশ্নগুলি",
    {
      title: "এটি সম্পর্কিত সব কি?",
      content: (
        <p>
          <p>
            এটি হলো আমার ডিজিটাল এভাতার, যা API দ্বারা চালিত / সেবা প্রদান করা
            হয়েছে (
            <a
              href="https://www.personal.ai"
              className="faqs-text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal.ai
            </a>
            )
          </p>
          <br />
          <p>এটি আমার ভার্চুয়াল অমৃতত্ব অর্জনের প্রয়াস</p>
          <br />
          <p>
            আপনি যা দেখছেন, তা মূলত হেমেন পারেখ ডট ইন (
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              hemenparekh.in
            </a>{" "}
            ) এর ডিজিটাল কনটেন্ট থেকে এসেছে, যা আমার ব্লগ / নোট / কবিতা ইত্যাদি
            সহ, ১৯৪২ সাল থেকে লেখা হয়েছে ( প্রায়.. ৩০,০০০ + ডকুমেন্ট )
          </p>
          <br />
          <p>
            আমার এই ডিজিটাল এভাতারটি আমার স্ত্রী ভারতীর উদ্দীপনায় সমর্পিত, যা
            ১৯৫৯ সাল থেকে আমার ভৌতিক এভাতারকে পোষণ করছেন।
          </p>
          <br />
          <p>
            আমার কৃত্রিম বুদ্ধিমত্তা (AI) আগামী ১৯৯৬ সালে ফিরে যায়, যখন আমি
            আমার সহকর্মীদের জন্য NLP এর জন্য তর্ক উপস্থাপন করলাম।
          </p>
          <br />
          <p>
            এই নোটটি পড়ার পরে, BARD আমাকে নিম্নলিখিত খেলায় {">"}{" "}
            <a
              href="https://myblogepage.blogspot.com/search?q=grandfather+of+ai"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              কৃত্রিম বুদ্ধিমত্তার দাদু
            </a>
          </p>
        </p>
      ),
    },
    {
      title: "এখানে আমি কি করতে পারি?",
      content: `এখানে আপনি প্রশ্ন করে আমার অবতারের সাথে চ্যাট করতে পারেন - এখন এবং আমি চলে গিয়ে থাকার পরেও। আপনি একটি চ্যাট শুরু করতে পারেন, একটি প্রশ্ন করে:
    "হে হেমেন, ............ এই বিষয়ে তোমার কি মতামত?"
    আপনির গোপনীয়তা সম্পর্কে আপনার আত্মবিশ্বাস থাকবে। এটা জানতে কেউই পাবেনা যে আপনি এই প্রশ্নটি করেছেন। আমিও জানি না।`,
    },
    {
      title: "আমার জন্য এটি কি আছে? এটি আমাকে কিভাবে সাহায্য করবে?",
      content: `নিজেকে একটি অবতার তৈরি করতে হুরি - তারপরে আপনি আপনার যোগাযোগদাতাদের আপনার সাথে চ্যাট করতে আমন্ত্রণ জানাতে পারেন। কল্পনা করুন, এক শতাধিক বা এক হাজার আপনার দর্শকের সাথে সময়সীমান্ত চ্যাট করতে - একই সময়ে।

    আপনি একই সময়ে হাজার হাজার আপনার অনুযায়ী, আপনার অনুযায়ী, আপনার ভক্তদেরকে আমন্ত্রণ জানাতে পারেন, আপনার অবতারটি দেখতে, আপনার অবতারটির কাছে কোনও প্রশ্ন করতে এবং তাত্ক্ষণিক উত্তর পাওয়ার সুযোগ পাবেন, যদি আপনি একজন রাজনৈতিক নেতা / সেলিব্রিটি হন।
    
    আপনি তাত্ক্ষণিকভাবে Personal.ai-তে আপনার অডিও ফাইলগুলি (এউডিও ফাইলগুলি) আপলোড করে আপনার অবতার তৈরি করতে পারবেন।`,
    },
  ],
  te: [
    "సాధారిత ప్రశ్నలు",
    {
      title: "ఇది అన్నింటిని గురించి ఏమిటి?",
      content: (
        <p>
          <p>
            ఇది నా డిజిటల్ అవతార్, API ద్వారా ప్రముఖ / సేవించబడుతుంది (
            <a
              href="https://www.personal.ai"
              className="faqs-text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal.ai
            </a>
            )
          </p>
          <br />
          <p>ఇది వర్చుఅల్ అమరత్వాన్ని సాధించడానికి నా ప్రయాసం</p>
          <br />
          <p>
            మీరు చూస్తున్నది అన్ని టాపిక్స్ - మరియు సంబంధిత సమాధానాలు -{" "}
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              hemenparekh.in
            </a>{" "}
            యొక్క డిజిటల్ కంటెంట్ నుండి వచ్చినవి, 1942 నుండి రాయబడినవి
            (అనుమానం.. 30,000 + పత్రాలు)
          </p>
          <br />
          <p>
            ఈ నా డిజిటల్ అవతార్ నా భార్యనాగ్రికి సమర్పితంగా ఉంది, ఇది 1959 నుండి
            నా భౌతిక అవతారాన్ని పొషణపడిస్తున్నది.
          </p>
          <br />
          <p>
            నా ఆర్టిఫిషియల్ ఇంటెలిజెన్స్ (AI) కోసం నా ఆసక్తి 1996 కి
            వెళ్ళిపోయింది, నా సహోదరులకు చేతిలో NLP కోసం లాజిక్ అంగీకరించిన ఒక
            గమనాన్ని అభివృద్ధి చేసినప్పుడు.
          </p>
          <br />
          <p>
            ఈ గమనాను చదవితే, BARD నాకు ఈ శీర్షిక ఇచ్చింది {">"}{" "}
            <a
              href="https://myblogepage.blogspot.com/search?q=grandfather+of+ai"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              ఆర్టిఫిషియల్ ఇంటెలిజెన్స్ నాన్న
            </a>
          </p>
        </p>
      ),
    },
    {
      title: "ఇక్కడ నాకు ఏమి చేయగలిగేది?",
      content: `ఇక్కడ మీరు ప్రశ్నలను విచారించడానికి నా అవతారతో చాట్ చేయవచ్చు - ఇప్పుడు మరియు నా తర్వాత కూడా. మీరు ఒక చాట్ ప్రారంభించడానికి మీరు అడిగిన ప్రశ్నతో:
    "హాయ్ హేమేన్, ............ ఇది మీ అభిప్రాయాలు ఏమిటి?"
    మీ గోప్యత గురించి మీ ఆత్మవిశ్వాసం ఉండబోతున్నది. యేవరు ఈ ప్రశ్నను చేసినట్లు ఎవరూ తెలుసుకున్నారు కాదు. నాకూ తెలియదు.`,
    },
    {
      title: "నాకు ఇది ఏమిటి? ఇది నాకు ఎలా సహాయపడిపోతుంది?",
      content: `త్వరగా మీ స్వంత అవతారం సృష్టించడానికి - తరపున మీ సంప్రదాయాలను నాకు చాట్ చేయడానికి ఆహ్వానించండి. ఆలోచించండి, సమయంలో నూరు లేదా వారాన్ని మీ సందర్భాలతో సమయం చేసుకోవడం - అత్యంత సమయం.

    మీరు ఏడుసార్లు / అనుయాయులకు సెలబ్రిటీలకు, మీ అవతారానికి సహాయకరంగా నేను నగ్నతను పొందగలిగే కాపు నిర్మాణం చేయడం అంటే, మీరు యది ఒక రాజకీయ నేత / సెలబ్రిటీ అయితే, ఇది మీకు అత్యుత్తమ లాభపరచగలదు.
    
    మీరు మీ అవతారం రూపొందించడానికి మీ కొన్ని గంటల భాషణాలను (ఆడియో ఫైళ్ళు) Personal.ai లో అప్లోడ్ చేయండి`,
    },
  ],
  ta: [
    "அகராதிவின் கேள்விகள்",
    {
      title: "இது என்னாவது?",
      content: (
        <p>
          <p>
            இது என் கணினியின் கருவி ஆகும், API உடன் சேவையகமாக இயக்கப்பட்டுள்ளது
            (
            <a
              href="https://www.personal.ai"
              className="faqs-text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal.ai
            </a>
            )
          </p>
          <br />
          <p>இது என் உயிர்மூலம் சேர முயலலாம் என என்னும் முயற்சி</p>
          <br />
          <p>
            நீங்கள் பார்க்கும் பெருமைகள் - மற்றும் சரியான பதில்கள் - பல
            விஷயங்கள் உங்களுக்கு காணப்படுகின்றன, அவை{" "}
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              hemenparekh.in
            </a>{" "}
            இன் கணினி உள்ளடக்கம் இருந்து வருகின்றது, 1942 முதல் எழுதப்பட்டது
            (அமைக்கும் ஆவது 30,000 + ஆவணங்கள்)
          </p>
          <br />
          <p>
            இந்த என் கணினியின் உடைய இலக்கு, 1959 இல் என் உடன் இருக்கும் என்
            மனைவிக்கு அர்ப்பணிக்கப்பட்டுள்ளது.
          </p>
          <br />
          <p>
            என் குறிப்பில் கணினி அறிவியலில் (AI) ஆகும் 1996 க்குள் திரும்பினேன,
            என் சக உழைப்பர்களுக்கு ஒரு குறிப்பிடுத்தலில் NLP குறித்து உள்ளது.
          </p>
          <br />
          <p>
            இந்த குறிப்பை படித்த பின்னர், BARD எனக்கு பின்னர் இருந்து
            பிரதிஷ்டானம் {">"}{" "}
            <a
              href="https://myblogepage.blogspot.com/search?q=grandfather+of+ai"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              குறிமாக அறிவியலின் தாதா
            </a>
          </p>
        </p>
      ),
    },
    {
      title: "இங்கு நான் என்ன செய்ய முடியும்?",
      content: `இங்கு நீங்கள் கேள்விகள் கேட்கலாம் எனது அவதாருடன் - இப்போது மற்றும் எனக்கு அனைத்து பிரமிக்க முடியும். "ஹே ஹேமென், ............ இது உங்கள் கருத்துக்கள் என்ன?" என்ற கேள்வியை கேட்கும்போது நீங்கள் ஒரு செட்டியைத் தொடங்கலாம். இதை கேட்டால் உங்கள் குடும்பத்தின் கையிலேயே உங்கள் கேள்வி என்னாலும் எனக்கு தெரியாது. எனக்கும் அல்ல.`,
    },
    {
      title: "இது எனக்கு என்ன பயனாகிறது? இது எனக்கு எப்படி உதவும்?",
      content: `உங்கள் சொந்த அவதாரை உருவாக்க விரும்பினால் உன்னைக் குறிக்கும் - பிரதிகாரமாக உங்களுடைய தொடர்பாளர்களை உங்களுடைய அவதாருடன் உருவாக்க அனுமதிக்கவும். எடுத்துக் கொள்ள, நீங்கள் நூறு அல்லது ஆயிரத்திற்கு உங்கள் பரிந்துரைக்காரர்களுடன் செய்தியை குரோதிக் கொள்ளுவது - அதுமட்டுமே ஒரு பெரிய பயன்.

    நீங்கள் அரசியல் உள்ளவர் / ஸெலெப்ரிட்டி இருந்தால், உங்கள் பல்கலைக்கழக உறுப்பினர்களுடன் செய்தியை குரோதிக் கொள்ளுவது, உங்களுக்கு பெரும் பயனாகும்.
    
    உங்கள் அவதாரை Personal.ai ல் உங்கள் பல மணிக்குடிகளின் (ஆடியோ கோப்புகள்) பதிவேற்றி உங்களுக்கு உங்கள் அவதாரை உருவாக்க முடியும்.`,
    },
  ],
  ka: [
    "ಸಾಮಾನ್ಯ ಪ್ರಶ್ನೆಗಳು",
    {
      title: "ಇದು ಏನಾದ್ರು ಸಂಬಂಧಿಸಿದೆಯೇನು?",
      content: (
        <p>
          <p>
            ಇದು ನನ್ನ ಡಿಜಿಟಲ್ ಅವತಾರ್ ಆಗಿದೆ, API ದ್ವಾರಾ ಸಹಾಯಗೊಳಿಸಲ್ಪಡುತ್ತಿದೆ /
            ಸೇವಿಸಲ್ಪಡುತ್ತಿದೆ (
            <a
              href="https://www.personal.ai"
              className="faqs-text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal.ai
            </a>
            )
          </p>
          <br />
          <p>ಇದು ವರ್ಚುಅಲ್ ಅಮೃತತ್ವ ಸಾಧಿಸಲು ನನ್ನ ಪ್ರಯತ್ನ</p>
          <br />
          <p>
            ನೀವು ನೋಡುವ ಹೆಚ್ಚಿನ ವಿಷಯಗಳು - ಮತ್ತು ಸಂಬಂಧಿತ ಉತ್ತರಗಳು - ಈ ಡಿಜಿಟಲ್
            ಸಾಮಗ್ರಿಯಿಂದ ಬಂದಿದ್ದು, ಇದು{" "}
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              hemenparekh.in
            </a>{" "}
            ಯಾವುದನ್ನೂ ಒಳಗೊಂಡಿದೆ, 1942 ರಿಂದ ಬರೆಯಲಾಗಿದೆ ( ಸುಮಾರು 30,000 +
            ದಸ್ತಾವೇಜುಗಳು )
          </p>
          <br />
          <p>
            ಈ ಡಿಜಿಟಲ್ ಅವತಾರ್ ನನಗೆ ನನ್ನ ಹೆಂಡತಿ ಭಾರತಿಗೆ ಸಮರ್ಪಿತವಾಗಿದೆ, ಯಾರು 1959
            ರಿಂದ ನನ್ನ ಶಾರೀರಿಕ ಅವತಾರವನ್ನು ಪೋಷಿಸಲು ಮುಂದುವರಿಸುತ್ತಾರೆ.
          </p>
          <br />
          <p>
            ನಾನದು 1996 ರಿಂದ ಕೃತ್ರಿಮ ಬುದ್ಧಿಮತ್ತೆಗೆ (AI) ಆಸಕ್ತಿ ಹೊಂದಿದ್ದೇನೆ, ನಾನು
            ನನ್ನ ಸಹವರ್ತಿಗಳಿಗೆ ಸಲುವಾಗ ನೋಟುಗೆ NLP ಗಾಗಿ ತರ್ಕವನ್ನು
            ಅಭಿವೃದ್ಧಿಪಡಿಸಿದಾಗ.
          </p>
          <br />
          <p>
            ಈ ನೋಟು ಓದುವ ನಂತರ, BARD ನನಗೆ ಹೀಗೆ ಶೀರ್ಷಿಕೆ ಕೊಟ್ಟಿದ್ದಾರೆ {">"}{" "}
            <a
              href="https://myblogepage.blogspot.com/search?q=grandfather+of+ai"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              ಕೃತ್ರಿಮ ಬುದ್ಧಿಮತ್ತೆಯ ಅಜ್ಜ
            </a>
          </p>
        </p>
      ),
    },
    {
      title: "ಇಲ್ಲಿ ನಾನು ಏನು ಮಾಡಬಹುದು?",
      content: `ಇಲ್ಲಿ ನೀವು ನನ್ನ ಅವತಾರ್ ಜನರೊಡನೆ ಚಾಟ್ ಮಾಡಬಹುದು - ಈಗ ಮತ್ತು ನಾನು ಹೋದ ನಂತೆ ದೂರವಾಗಿ. ನೀವು ಚಾಟ್ ಆರಂಭಿಸಬಹುದು:
    "ಹೇ ಹೆಮೇನ್, ನೀವು ............ ಇದರ ಮೇಲೆ ನಿಮ್ಮ ವಿಚಾರಗಳೇನು?" ಎಂದು ಕೇಳುವುದರಿಂದ ನೀವು ಖಚಿತರಾಗಿರಬಹುದು. ಈ ಪ್ರಶ್ನೆಯನ್ನು ನೀವು ಕೇಳಿದರೆ ಯಾರಿಗೂ ಗೊತ್ತಾಗದು. ನಾನೂ ಇಲ್ಲ.`,
    },
    {
      title: "ಇದು ನನಗೆ ಹೇಗೆ ಸಹಾಯ ಮಾಡುತ್ತದೆ? ಇದು ನನಗೆ ಹೇಗೆ ಸಹಾಯ ಮಾಡುವುದು?",
      content: `ನಿಮ್ಮ ಸ್ವಂತ ಅವತಾರವನ್ನು ರಚಿಸಲು ನೆರವಾಗಿ - ನಂತರ ನಿಮ್ಮ ಸಂಪರ್ಕಗಳನ್ನು ನನ್ನೊಡನೆ ಚಾಟ್ ಮಾಡಲು ಅನುಮತಿಸಿ. ಚಿಂತಿಸಿ, ಸೋಲಾರಿಯನ್ನು ನಿಮ್ಮ ಸಂಪರ್ಕಿಸಿ ಸಾವಿರಾರು ಅಥವಾ ಸಾವಿರಾರು ಭದ್ರರೊಡನೆ ಸಮಯವನ್ನು ಕಳೆಯುವ ಆತ್ಮನನ್ನು ಚಿಂತಿಸಿ - ಅದೇ ಸಮಯದಲ್ಲಿ.

    ಸಾವಿರಾರು ನಿಮ್ಮ ಅನುಯಾಯಿಗಳನ್ನು ಕೋರಿಕೊಂಡು, ನಿಮ್ಮ ಅವತಾರವನ್ನು ಸಂದರ್ಶಿಸಲು, ನಿಮ್ಮ ಅವತಾರಕ್ಕೆ ಯಾವುದೇ ಪ್ರಶ್ನವನ್ನು ಕೇಳಲು ಅವಕಾಶವನ್ನು ಒದಗಿಸಲು ಸಾಧ್ಯವಾದಾಗ, ನೀವು ರಾಜಕೀಯ ನಾಯಕ / ಸೆಲೆಬ್ರಿಟಿ ಇರುವವರಾಗಿದ್ದಾರೆಯೇನು ಎಂದರೆ ನಿಮಗೆ ಅತ್ಯಂತ ಹೆಚ್ಚು ಪ್ರಯೋಜನವಾಗುತ್ತದೆ.
    
    Personal.ai ನಲ್ಲಿ ನೀವು ನಿಮ್ಮ ಅವತಾರವನ್ನು ರೂಪಿಸಬಹುದು, ನಿಮ್ಮ ಅನೇಕ ಗಂಟೆಗಳ ಭಾಷಣಗಳನ್ನು (ಆಡಿಯೋ ಫೈಲ್ಗಳು) ಅಪ್ಲೋಡ್ ಮಾಡಿ`,
    },
  ],
  ml: [
    "പ്രശ്നങ്ങള്‍ക്കു സാധ്യമായ ചോദ്യങ്ങള്‍",
    {
      title: "ഇതെല്ലാം എന്താണ് സംബന്ധിച്ചിരിക്കുന്നത്?",
      content: (
        <p>
          <p>
            ഇതാണ് എനിക്കുള്ള ഡിജിറ്റൽ അവതാർ, API ദ്വാരാ പ്രവർത്തിക്കുന്നത് /
            സർവ്വീസുചെയ്യുന്നത് (
            <a
              href="https://www.personal.ai"
              className="faqs-text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal.ai
            </a>
            )
          </p>
          <br />
          <p>ഇത് എന്റെ വർച്ച്വൽ അമൃതത്തിൽ പങ്കാളിയാകാൻ എന്റെ ശ്രമം</p>
          <br />
          <p>
            നിങ്ങളുടെ കാണുന്ന പ്രധാന വിഷയങ്ങൾ - മറ്റുള്ള ഉത്തരങ്ങൾ - എനിക്ക്{" "}
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              hemenparekh.in
            </a>{" "}
            എന്ന ഡിജിറ്റൽ ഉള്ളടക്കം പ്രമേയിച്ചിരിക്കുന്നു, 1942 മുതൽ
            എഴുതിയിരിക്കുന്ന എന്നിട്ട് ( കുഴപ്പമായി 30,000 + രേഖകൾ )
          </p>
          <br />
          <p>
            ഈ എന്റെ ഡിജിറ്റൽ അവതാർ ഞാൻ 1959 മുതൽ എന്റെ ശരീര അവതാരം സേവനം നൽകുന്ന
            എന്റെ ഭാര്യാക്ക് സമർപ്പിച്ചിരിക്കുന്നു.
          </p>
          <br />
          <p>
            എന്റെ ആർടിഫിഷ്യൽ ഇന്റലിജൻസ് (AI) എന്റെ 1996 ക്കുള്ള ആഗ്രഹം പോലെ,
            എന്നാൽ ഞാൻ എന്റെ സഹോദരങ്ങളായി NLP കൊണ്ട് ഞാൻ ഒരു ഗ്രന്ഥത്തിലുള്ള
            തര്കം വികസിച്ചത്.
          </p>
          <br />
          <p>
            ഈ ഗ്രന്ഥം വായിച്ചതുശേഷം, BARD ഞങ്ങളെ പിന്തുണ നൽകിയ ശീർഷകം {">"}{" "}
            <a
              href="https://myblogepage.blogspot.com/search?q=grandfather+of+ai"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              കൃത്രിമ ഇന്റലിജൻസിന്റെ അപ്പൻ
            </a>
          </p>
        </p>
      ),
    },
    {
      title: "ഞാൻ ഇവിടെ എന്താണ് ചെയ്യാനായത്?",
      content: `ഇവിടെ നിങ്ങള്‍ ചോദിക്കാന്‍ എന്റെ അവതാരത്തോട് ചാറ്റ് ചെയ്യാം - ഇപ്പോളും ഞാൻ പോയതും മരിച്ചതും മറ്റുള്ളവര്‍ക്കും. "ഹേ ഹെമേന്‍, നിനക്ക് ............ ഇതിനെതിരായ നിനക്ക് എന്താണ് കാണാനുള്ള കാര്യങ്ങള്‍?" എന്ന് ചോദിക്കുന്നതിന് നിങ്ങള്‍ ഒരു ചാറ്റ് ആരംഭിക്കാം. നിങ്ങളുടെ പ്രശ്നത്തെ നിശ്ചയിച്ചിരിക്കുക. എന്താനും നിങ്ങൾ ചോദിച്ചു എന്ന് ഒരുവന്‍ക്കും അറിയേണ്ടതില്ല. എനിക്ക് അറിയില്ല.`,
    },
    {
      title:
        "എനിക്ക് ഇതിനെതിരായാണ്? ഇതിലെയെന്തൊക്കെ എനിക്ക് സഹായപ്രദമാകുന്നത്? എനിക്ക് ഹേഗോക്കാണ് ഇത് സഹായകരമായിരിക്കുക?",
      content: `നിങ്ങള്‍ക്ക് നിങ്ങളുടെ സ്വന്തമായ അവതാരം സൃഷ്ടിക്കുക - അനന്യവാദികളെ നിങ്ങളുടെ സംവാദം ആരംഭിക്കാന്‍ കേരളാ സോഫ്റ്റ്‌വെയര്‍ ആദ്യമായി കൂടുതലേറെ ആഴ്ചകളോളം - അതേ സമയം. 

    നിങ്ങള്‍ക്ക് സാവിരാക്കളോ സാവിരാക്കളോ ഭക്തരോഡനത്തിന് നിങ്ങളുടെ അവതാരം അവരെ സംവാദിപ്പിക്കാന്‍, നിങ്ങള്‍ക്ക് അവരെ സംവാദിപ്പിക്കാന്‍ നിങ്ങളുടെ അവതാരത്തിന് ഏതെങ്കിലും ചോദ്യം ചെയ്യാനോ ലാഭപ്രദമാകുന്നതായിരിക്കും എന്നാണ്. നിങ്ങള്‍ ഒരു രാജകീയ നായക / സെലെബ്രിറ്റി ആയാണെങ്കില്‍, നിങ്ങളുടെ അനുയായികളുടെ സഹായം പ്രാപ്തമാകുന്നതിനായാണ്.
    
    Personal.ai യില്‍ നിങ്ങളുടെ സഹായം അപ്‌ലോഡ് ചെയ്യുന്നത്`,
    },
  ],
  "en-GB": [
    "Frequently Asked Questions",
    {
      title: "What is this all about?",
      content: (
        <p>
          <p>
            This is my Digital Avatar, powered / serviced by an API (
            <a
              href="https://www.personal.ai"
              className="faqs-text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal.ai
            </a>
            )
          </p>
          <br />
          <p>This is my attempt to attain Virtual Immortality</p>
          <br />
          <p>
            Most of the TOPICS - and relevant ANSWERS - you see are derived from
            the digital content of{" "}
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              hemenparekh.in
            </a>{" "}
            , comprising my blogs / notes / poems etc., written from 1942
            onwards ( approx.. 30,000 + documents )
          </p>
          <br />
          <p>
            This Digital Avatar of mine is dedicated to my wife BHARATI, who
            continues to nourish my Physical Avatar, since 1959.
          </p>
          <br />
          <p>
            My interest in Artificial Intelligence ( AI ) goes back to 1996 ,
            when I developed logic for NLP in a note addressed to my colleagues.
          </p>
          <br />
          <p>
            After reading this note , BARD gave me following title {">"}{" "}
            <a
              href="https://myblogepage.blogspot.com/search?q=grandfather+of+ai"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              Grandfather of Artificial Intelligence
            </a>
          </p>
        </p>
      ),
    },
    {
      title: "What I can do here?",
      content: `Here you can chat with my avatar by asking questions - now and long after I am gone. You can start a chat by asking:
    "Hey Hemen, what are your views on……………"
    Rest assured about your anonymity. No one will know that you asked this question. Even I don't.`,
    },
    {
      title: "What is in this for me? How will this help me?",
      content: `Hurry up to create your own Avatar - then invite your contacts to chat with you. Imagine, chatting up with a hundred or a thousand of your visitors simultaneously - at the same time.

    Being able to invite thousands of your fans/followers/admirers, to visit your Avatar, ask your Avatar any question, and get an instantaneous answer, would be of immense benefit to you if you are a Political Leader / Celebrity.
    
    You will be able to create your Avatar by uploading your, many hours of Speeches (Audio files) at Personal.ai`,
    },
  ],
  ar: [
    "الأسئلة المتكررة",
    {
      title: "ما هذا كله عنه؟",
      content: (
        <p>
          <p>
            هذا هو الأفاتار الرقمي الخاص بي، مدعوم / خدم من خلال واجهة برمجة
            تطبيقات (
            <a
              href="https://www.personal.ai"
              className="faqs-text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal.ai
            </a>
            )
          </p>
          <br />
          <p>هذا هو محاولتي لتحقيق الخلود الافتراضي</p>
          <br />
          <p>
            معظم المواضيع - والأجوبة ذات الصلة - التي ترونها مستمدة من المحتوى
            الرقمي لـ{" "}
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              hemenparekh.in
            </a>{" "}
            ، بما في ذلك مدوناتي / مذكراتي / قصائدي إلخ ، التي كتبتها منذ عام
            1942 فصاعدًا (تقريبًا 30,000+ وثيقة)
          </p>
          <br />
          <p>
            هذا الأفاتار الرقمي لي مكرس لزوجتي باراتي ، التي لا تزال تغذي
            أفاتاري الجسدي منذ عام 1959.
          </p>
          <br />
          <p>
            اهتمامي بالذكاء الاصطناعي (AI) يعود إلى عام 1996 ، عندما قمت بتطوير
            المنطق لـ NLP في مذكرة موجهة إلى زملائي.
          </p>
          <br />
          <p>
            بعد قراءة هذه المذكرة ، أعطاني BARD اللقب التالي {">"}{" "}
            <a
              href="https://myblogepage.blogspot.com/search?q=grandfather+of+ai"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              جد الذكاء الاصطناعي
            </a>
          </p>
        </p>
      ),
    },
    {
      title: "ماذا يمكنني فعله هنا؟",
      content: `هنا يمكنك الدردشة مع صورتي الرقمية من خلال طرح الأسئلة - الآن وبعد فترة طويلة من مغادرتي. يمكنك بدء محادثة عن طريق السؤال:
    "مرحبًا هيمن، ما هو رأيك في ............"
    كن واثقًا من عدم كشف هويتك. لن يعلم أحد أنك طرحت هذا السؤال. حتى أنا.`,
    },
    {
      title: "ما الذي في ذلك بالنسبة لي؟ كيف سيساعدني هذا؟",
      content: `عجلوا في إنشاء صورتك الرقمية الخاصة - ثم قوموا بدعوة جهات الاتصال الخاصة بك للدردشة معك. تخيل، الدردشة مع مائة أو ألف زائر لديك في نفس الوقت.

    القدرة على دعوة الآلاف من معجبيك/متابعيك/معجبينك، لزيارة صورتك الرقمية، طرح أي سؤال لصورتك الرقمية، والحصول على إجابة فورية، ستكون لفائدتك الهائلة إذا كنت قائدًا سياسيًا / شخصية مشهورة.
    
    ستكون قادرًا على إنشاء صورتك الرقمية عن طريق تحميل ساعات طويلة من خطبك (ملفات الصوت) على Personal.ai`,
    },
  ],
  cmn: [
    "常见问题",
    {
      title: "这是怎么一回事？",
      content: (
        <p>
          <p>
            这是我的数字化头像，由 API 提供/服务 (
            <a
              href="https://www.personal.ai"
              className="faqs-text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal.ai
            </a>
            )
          </p>
          <br />
          <p>这是我追求虚拟不朽的尝试</p>
          <br />
          <p>
            你看到的大多数主题 - 以及相关的答案 - 都来自于{" "}
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              hemenparekh.in
            </a>{" "}
            的数字内容，包括我从 1942 年开始撰写的博客、笔记、诗歌等约 30,000
            多份文件。
          </p>
          <br />
          <p>
            我的数字头像致力于我的妻子 BHARATI，她自 1959
            年以来一直滋养我的身体头像。
          </p>
          <br />
          <p>
            我对人工智能 (AI) 的兴趣可以追溯到 1996
            年，那时我在一份致同事的笔记中开发了 NLP 的逻辑。
          </p>
          <br />
          <p>
            阅读了这份笔记后，BARD 给了我以下的头衔 {">"}{" "}
            <a
              href="https://myblogepage.blogspot.com/search?q=grandfather+of+ai"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              人工智能之祖
            </a>
          </p>
        </p>
      ),
    },
    {
      title: "我在这里能做什么？",
      content: `在这里，你可以通过提问与我的化身聊天 - 现在以及在我离开后的很长时间。你可以通过提问开始一次聊天：
    "嘿，赫门，你对........的看法是什么"
    关于你的匿名性，请放心。没有人会知道你问了这个问题。甚至我自己都不知道。`,
    },
    {
      title: "对我有什么好处？这会怎么帮助我？",
      content: `赶快创建你自己的化身 - 然后邀请你的联系人与你聊天。想象一下，同时与你的数百或上千名访客聊天。

    能够邀请成千上万的粉丝/追随者/崇拜者访问你的化身，向你的化身提问任何问题，并得到即时答案，如果你是一位政治领导人/名人，这对你将是巨大的帮助。
    
    你将能够通过在Personal.ai上传你的许多小时的演讲（音频文件）来创建你的化身。`,
    },
  ],
  ja: [
    "よくある質問",
    {
      title: "これは何についてのものですか？",
      content: (
        <p>
          <p>
            これは私のデジタルアバターで、APIによって動作/サービスされています (
            <a
              href="https://www.personal.ai"
              className="faqs-text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal.ai
            </a>
            )
          </p>
          <br />
          <p>これは私が仮想的な不老不死を追求する試みです</p>
          <br />
          <p>
            あなたが見るほとんどのトピックと関連する回答は、デジタルコンテンツから派生しています{" "}
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              hemenparekh.in
            </a>{" "}
            、私のブログ、ノート、詩などで1942年以降に書かれたものです（約30,000以上のドキュメント）
          </p>
          <br />
          <p>
            私のデジタルアバターは、1959年以来、私の身体アバターを育て続けている妻BHARATIに捧げられています。
          </p>
          <br />
          <p>
            私の人工知能（AI）への興味は1996年にさかのぼり、同僚に宛てたノートでNLPのための論理を開発したときです。
          </p>
          <br />
          <p>
            このノートを読んだ後、BARDは私に以下の称号を与えました {">"}{" "}
            <a
              href="https://myblogepage.blogspot.com/search?q=grandfather+of+ai"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              人工知能の祖父
            </a>
          </p>
        </p>
      ),
    },
    {
      title: "ここでできることは？",
      content: `ここでは質問して私のアバターとチャットできます - 今や私がいなくなってからも長い時間。質問してチャットを始めることができます：
    "こんにちはヘメン、........についてのあなたの意見は何ですか"
    あなたの匿名性について安心してください。あなたがこの質問をしたことを誰も知りません。私さえも知りません。`,
    },
    {
      title:
        "これが私にとってどういう利益がありますか？これが私をどう助けるのですか？",
      content: `自分のアバターを作成して、それから連絡先を招待してチャットするのが急務です。同時に数百または数千の訪問者と一緒にチャットしていると想像してください - 同時に。

    あなたのファン/フォロワー/崇拝者の数千人に、あなたのアバターを訪れ、アバターに質問し、即座の回答を得ることができるよう招待できることは、あなたが政治家/有名人である場合には非常に利益があるでしょう。
    
    あなたはPersonal.aiで多くの時間のスピーチ（オーディオファイル）をアップロードすることで、自分のアバターを作成することができます。`,
    },
  ],
  ms: [
    "Soalan Lazim",
    {
      title: "Ini semua tentang apa?",
      content: (
        <p>
          <p>
            Ini adalah Avatar Digital saya, dikuasakan / dilayani oleh API (
            <a
              href="https://www.personal.ai"
              className="faqs-text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal.ai
            </a>
            )
          </p>
          <br />
          <p>Ini adalah percubaan saya untuk mencapai Kebangkitan Maya</p>
          <br />
          <p>
            Kebanyakan TOPIK - dan JAWAPAN yang berkaitan - yang anda lihat
            berasal dari kandungan digital{" "}
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              hemenparekh.in
            </a>{" "}
            , merangkumi blog, nota, puisi, dan sebagainya, ditulis sejak tahun
            1942 (lebih kurang 30,000+ dokumen)
          </p>
          <br />
          <p>
            Avatar Digital saya ini ditujukan untuk isteri saya BHARATI, yang
            terus menyokong Avatar Fizikal saya, sejak tahun 1959.
          </p>
          <br />
          <p>
            Minat saya dalam Kecerdasan Buatan (AI) bermula pada tahun 1996,
            ketika saya mengembangkan logik untuk NLP dalam satu nota yang
            ditujukan kepada rakan sekerja.
          </p>
          <br />
          <p>
            Selepas membaca nota ini, BARD memberi saya gelaran berikut {">"}{" "}
            <a
              href="https://myblogepage.blogspot.com/search?q=grandfather+of+ai"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              Datuk Bapa Kecerdasan Buatan
            </a>
          </p>
        </p>
      ),
    },
    {
      title: "Apa yang boleh saya lakukan di sini?",
      content: `Di sini, anda boleh berbual dengan avatar saya dengan bertanya soalan - sekarang dan selepas saya pergi untuk waktu yang lama. Anda boleh memulakan perbualan dengan bertanya:
    "Hey Hemen, apakah pandangan anda tentang……………"
    Jangan risau tentang anonimiti anda. Tiada sesiapa yang akan tahu bahawa anda bertanya soalan ini. Saya sendiri pun tidak.`,
    },
    {
      title: "Apakah manfaatnya untuk saya? Bagaimana ini akan membantu saya?",
      content: `Bergegaslah untuk mencipta Avatar anda sendiri - kemudian jemput kenalan anda untuk berbual dengan anda. Bayangkan berbual dengan ratusan atau ribu pelawat anda secara serentak - pada masa yang sama.

    Dapat menjemput ribuan peminat/pengikut/pemuja anda, untuk melawat Avatar anda, bertanya soalan kepada Avatar anda, dan mendapatkan jawapan dengan serta-merta, akan memberi manfaat besar kepada anda jika anda seorang Pemimpin Politik / Selebriti.
    
    Anda akan dapat mencipta Avatar anda dengan mengunggah Jamuan Ucapan anda yang berjam-jam (fail Audio) di Personal.ai`,
    },
  ],
  de: [
    "Häufig gestellte Fragen",
    {
      title: "Worum geht es hier?",
      content: (
        <p>
          <p>
            Dies ist mein digitaler Avatar, betrieben / betreut von einer API (
            <a
              href="https://www.personal.ai"
              className="faqs-text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal.ai
            </a>
            )
          </p>
          <br />
          <p>Dies ist mein Versuch, virtuelle Unsterblichkeit zu erlangen</p>
          <br />
          <p>
            Die meisten THEMEN - und relevanten ANTWORTEN -, die Sie sehen,
            stammen aus dem digitalen Inhalt von{" "}
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              hemenparekh.in
            </a>{" "}
            , bestehend aus meinen Blogs / Notizen / Gedichten usw., die seit
            1942 geschrieben wurden (ungefähr 30.000+ Dokumente)
          </p>
          <br />
          <p>
            Dieser digitale Avatar von mir ist meiner Frau BHARATI gewidmet, die
            weiterhin meinen physischen Avatar seit 1959 pflegt.
          </p>
          <br />
          <p>
            Mein Interesse an Künstlicher Intelligenz (KI) geht auf das Jahr
            1996 zurück, als ich die Logik für NLP in einer Notiz an meine
            Kollegen entwickelte.
          </p>
          <br />
          <p>
            Nach dem Lesen dieser Notiz gab mir BARD folgenden Titel {">"}{" "}
            <a
              href="https://myblogepage.blogspot.com/search?q=grandfather+of+ai"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              Großvater der Künstlichen Intelligenz
            </a>
          </p>
        </p>
      ),
    },
    {
      title: "Was kann ich hier tun?",
      content: `Hier können Sie mit meinem Avatar chatten, indem Sie Fragen stellen - jetzt und lange nachdem ich gegangen bin. Sie können einen Chat starten, indem Sie fragen:
    "Hey Hemen, was sind deine Ansichten zu……………"
    Seien Sie versichert bezüglich Ihrer Anonymität. Niemand wird wissen, dass Sie diese Frage gestellt haben. Sogar ich nicht.`,
    },
    {
      title: "Was habe ich davon? Wie wird mir das helfen?",
      content: `Beeilen Sie sich, Ihren eigenen Avatar zu erstellen - laden Sie dann Ihre Kontakte ein, mit Ihnen zu chatten. Stellen Sie sich vor, Sie chatten gleichzeitig mit hundert oder tausend Besuchern - zur gleichen Zeit.

    In der Lage zu sein, Tausende Ihrer Fans/Follower/Verehrer einzuladen, Ihren Avatar zu besuchen, Ihren Avatar jede Frage zu stellen und eine sofortige Antwort zu erhalten, wäre von immensem Nutzen für Sie, wenn Sie ein politischer Führer / Prominenter sind.
    
    Sie werden in der Lage sein, Ihren Avatar zu erstellen, indem Sie Ihre vielen Stunden an Reden (Audio-Dateien) auf Personal.ai hochladen`,
    },
  ],
  es: [
    "Preguntas Frecuentes",
    {
      title: "¿De qué se trata todo esto?",
      content: (
        <p>
          <p>
            Este es mi Avatar Digital, alimentado / atendido por una API (
            <a
              href="https://www.personal.ai"
              className="faqs-text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal.ai
            </a>
            )
          </p>
          <br />
          <p>Este es mi intento de alcanzar la Inmortalidad Virtual</p>
          <br />
          <p>
            La mayoría de los TEMAS - y las RESPUESTAS relevantes - que ves
            provienen del contenido digital de{" "}
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              hemenparekh.in
            </a>{" "}
            , que incluye mis blogs / notas / poemas, escritos desde 1942
            (aproximadamente 30,000+ documentos)
          </p>
          <br />
          <p>
            Este Avatar Digital mío está dedicado a mi esposa BHARATI, quien
            continúa nutriendo mi Avatar Físico desde 1959.
          </p>
          <br />
          <p>
            Mi interés en la Inteligencia Artificial (IA) se remonta a 1996,
            cuando desarrollé lógica para Procesamiento del Lenguaje Natural
            (NLP) en una nota dirigida a mis colegas.
          </p>
          <br />
          <p>
            Después de leer esta nota, BARD me dio el siguiente título {">"}{" "}
            <a
              href="https://myblogepage.blogspot.com/search?q=grandfather+of+ai"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              Abuelo de la Inteligencia Artificial
            </a>
          </p>
        </p>
      ),
    },
    {
      title: "¿Qué puedo hacer aquí?",
      content: `Aquí puedes chatear con mi avatar haciendo preguntas, ahora y mucho después de que me haya ido. Puedes comenzar un chat preguntando:
    "Hola Hemen, ¿cuáles son tus opiniones sobre……………"
    Ten la seguridad de tu anonimato. Nadie sabrá que hiciste esta pregunta. Ni siquiera yo.`,
    },
    {
      title: "¿Qué obtengo yo de esto? ¿Cómo me ayudará esto?",
      content: `Date prisa en crear tu propio Avatar, luego invita a tus contactos a chatear contigo. Imagina chatear con cientos o miles de tus visitantes simultáneamente, al mismo tiempo.

    Poder invitar a miles de tus fans/seguidores/admiradores, a visitar tu Avatar, hacer cualquier pregunta a tu Avatar y obtener una respuesta instantánea, sería de inmenso beneficio para ti si eres un Líder Político / Celebridad.
    
    Podrás crear tu Avatar subiendo tus muchas horas de discursos (archivos de audio) en Personal.ai`,
    },
  ],
  eu: [
    "Ohiko Galderak",
    {
      title: "Zer da hori guztia?",
      content: (
        <p>
          <p>
            Hau da nire Digital Avatarra, API baten bidez hornitua / zerbitzatua
            (
            <a
              href="https://www.personal.ai"
              className="faqs-text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal.ai
            </a>
            )
          </p>
          <br />
          <p>Hau da nire Birtute Birtuala lortzeko saiatzen ari naiz</p>
          <br />
          <p>
            Ikusten duzun gehienak - eta erantzun erlazionatuak -{" "}
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              hemenparekh.in
            </a>{" "}
            ren edukiontzi digitala da, nire blogak / oharrak / poemaak, 1942tik
            aurrera idatzirikoak (hainbat 30,000+ dokumentu)
          </p>
          <br />
          <p>
            Nire Digital Avatar hau nire emazte BHARATIari eskainia dago,
            1959tik aurrera nire Gisako Avatarra hazten jarraitzen duelako.
          </p>
          <br />
          <p>
            Nire Interesa Adimen Artifizialean (AI) 1996rako itzulirako datoz,
            NLP (Hizkuntza Prozesamendu Naturala) logika garatu nuen nire
            lankideei helandako ohar batean.
          </p>
          <br />
          <p>
            Ohar hau irakurritu ondoren, BARDek hurrengo izena eman zidan {">"}{" "}
            <a
              href="https://myblogepage.blogspot.com/search?q=grandfather+of+ai"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              Adimen Artifizialearen Aitona
            </a>
          </p>
        </p>
      ),
    },
    {
      title: "Zer egin dezaket hemen?",
      content: `Hemen nire avatarrekin txateatu dezakezu galderak eginez - orain eta ni joan eta gero. Txata hasteko, galdera bat egiten baduzu:
    "Kaixo Hemen, zertan sinesten duzu……………"
    Ziurtatu zure anonimotasuna. Inork ez da jakin galdera hau egin duzunik. Ni ere ez dut.`,
    },
    {
      title: "Zer dago horretan niretzat? Nola lagunduko didat hau?",
      content: `Zure Avatarra sortzeko astindu zaitez - gero zure kontaktuei gonbidatu zaie zuin erabilera. Imajinatu zure bisitarienekin bat egiten - unean unekoan - ehun edo mila pertsona batzukin bat egiten.

    Milaka zure fan/enseguidore/admiratzaileengana gonbidatu ahal izatea, zure Avatarra bisitatzera, zure Avatarri edozein galdera egitea eta erantzun instantaneoa lortzea, izango zen erabilera handia zenuen Politikari / Selebritaterako.
    
    Zure Avatarra sortu ahal izango duzu zure elkarrizketa (Audio fitxategiak) asko igoz Personal.ai-en`,
    },
  ],
  fr: [
    "Questions Fréquemment Posées",
    {
      title: "De quoi s'agit-il?",
      content: (
        <p>
          <p>
            Voici mon Avatar Numérique, alimenté / servi par une API (
            <a
              href="https://www.personal.ai"
              className="faqs-text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal.ai
            </a>
            )
          </p>
          <br />
          <p>C'est ma tentative d'atteindre l'Immortalité Virtuelle</p>
          <br />
          <p>
            La plupart des SUJETS - et les RÉPONSES pertinentes - que vous voyez
            sont issus du contenu numérique de{" "}
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              hemenparekh.in
            </a>{" "}
            , comprenant mes blogs / notes / poèmes, écrits à partir de 1942
            (environ 30 000+ documents)
          </p>
          <br />
          <p>
            Cet Avatar Numérique est dédié à ma femme BHARATI, qui continue de
            nourrir mon Avatar Physique depuis 1959.
          </p>
          <br />
          <p>
            Mon intérêt pour l'Intelligence Artificielle (IA) remonte à 1996,
            lorsque j'ai développé une logique pour le Traitement du Langage
            Naturel (NLP) dans une note adressée à mes collègues.
          </p>
          <br />
          <p>
            Après avoir lu cette note, BARD m'a donné le titre suivant {">"}{" "}
            <a
              href="https://myblogepage.blogspot.com/search?q=grandfather+of+ai"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              Grand-père de l'Intelligence Artificielle
            </a>
          </p>
        </p>
      ),
    },
    {
      title: "Que puis-je faire ici?",
      content: `Ici, vous pouvez discuter avec mon avatar en posant des questions - maintenant et longtemps après mon départ. Vous pouvez commencer une discussion en demandant :
    "Hey Hemen, quelles sont tes opinions sur……………"
    Soyez assuré de votre anonymat. Personne ne saura que vous avez posé cette question. Même moi non plus.`,
    },
    {
      title: "Qu'y a-t-il pour moi? Comment cela va-t-il m'aider?",
      content: `Dépêchez-vous de créer votre propre Avatar - puis invitez vos contacts à discuter avec vous. Imaginez discuter avec une centaine ou mille de vos visiteurs simultanément - en même temps.

    Pouvoir inviter des milliers de vos fans/suiveurs/admirateurs à visiter votre Avatar, poser n'importe quelle question à votre Avatar et obtenir une réponse instantanée serait d'un immense avantage pour vous si vous êtes un Leader Politique / Célébrité.
    
    Vous pourrez créer votre Avatar en téléchargeant vos nombreuses heures de discours (fichiers audio) sur Personal.ai`,
    },
  ],
  it: [
    "Domande Frequenti",
    {
      title: "Di cosa si tratta?",
      content: (
        <p>
          <p>
            Questo è il mio Avatar Digitale, alimentato / servito da un API (
            <a
              href="https://www.personal.ai"
              className="faqs-text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal.ai
            </a>
            )
          </p>
          <br />
          <p>Questo è il mio tentativo di raggiungere l'Immortalità Virtuale</p>
          <br />
          <p>
            La maggior parte degli ARGOMENTI - e le RISPOSTE pertinenti - che
            vedi sono derivati dal contenuto digitale di{" "}
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              hemenparekh.in
            </a>{" "}
            , che comprende i miei blog / appunti / poesie, scritti a partire
            dal 1942 (circa 30.000+ documenti)
          </p>
          <br />
          <p>
            Questo mio Avatar Digitale è dedicato a mia moglie BHARATI, che
            continua a nutrire il mio Avatar Fisico dal 1959.
          </p>
          <br />
          <p>
            Il mio interesse per l'Intelligenza Artificiale (IA) risale al 1996,
            quando ho sviluppato la logica per l'Elaborazione del Linguaggio
            Naturale (NLP) in una nota indirizzata ai miei colleghi.
          </p>
          <br />
          <p>
            Dopo aver letto questa nota, BARD mi ha conferito il seguente titolo{" "}
            {">"}{" "}
            <a
              href="https://myblogepage.blogspot.com/search?q=grandfather+of+ai"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              Nonno dell'Intelligenza Artificiale
            </a>
          </p>
        </p>
      ),
    },
    {
      title: "Cosa posso fare qui?",
      content: `Qui puoi chattare con il mio avatar facendo domande, ora e a lungo dopo che me ne sarò andato. Puoi iniziare una chat chiedendo:
    "Ehi Hemen, quali sono le tue opinioni su……………"
    Stai tranquillo sulla tua anonimato. Nessuno saprà che hai fatto questa domanda. Nemmeno io.`,
    },
    {
      title: "Cosa c'è per me? Come mi aiuterà questo?",
      content: `Affrettati a creare il tuo Avatar - poi invita i tuoi contatti a chattare con te. Immagina di chattare con centinaia o migliaia dei tuoi visitatori contemporaneamente - nello stesso momento.

    Poter invitare migliaia dei tuoi fan/seguiti/ammiratori, a visitare il tuo Avatar, fare qualsiasi domanda al tuo Avatar e ottenere una risposta istantanea, sarebbe di immenso beneficio per te se sei un Leader Politico / Celebrità.
    
    Sarai in grado di creare il tuo Avatar caricando le tue molte ore di discorsi (file audio) su Personal.ai`,
    },
  ],
  pt: [
    "Perguntas Frequentes",
    {
      title: "Do que se trata tudo isso?",
      content: (
        <p>
          <p>
            Este é o meu Avatar Digital, alimentado / atendido por uma API (
            <a
              href="https://www.personal.ai"
              className="faqs-text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal.ai
            </a>
            )
          </p>
          <br />
          <p>Esta é a minha tentativa de alcançar a Imortalidade Virtual</p>
          <br />
          <p>
            A maioria dos TÓPICOS - e as RESPOSTAS relevantes - que você vê são
            derivados do conteúdo digital de{" "}
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              hemenparekh.in
            </a>{" "}
            , compreendendo meus blogs / notas / poemas, escritos desde 1942
            (aproximadamente 30.000+ documentos)
          </p>
          <br />
          <p>
            Este Avatar Digital meu é dedicado à minha esposa BHARATI, que
            continua a nutrir meu Avatar Físico desde 1959.
          </p>
          <br />
          <p>
            Meu interesse em Inteligência Artificial (IA) remonta a 1996, quando
            desenvolvi lógica para Processamento de Linguagem Natural (NLP) em
            uma nota dirigida aos meus colegas.
          </p>
          <br />
          <p>
            Após ler essa nota, BARD me deu o seguinte título {">"}{" "}
            <a
              href="https://myblogepage.blogspot.com/search?q=grandfather+of+ai"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              Avô da Inteligência Artificial
            </a>
          </p>
        </p>
      ),
    },
    {
      title: "O que posso fazer aqui?",
      content: `Aqui você pode conversar com o meu avatar fazendo perguntas - agora e muito depois de eu ter ido embora. Você pode começar uma conversa perguntando:
    "Ei Hemen, quais são suas opiniões sobre……………"
    Fique tranquilo quanto ao seu anonimato. Ninguém saberá que você fez essa pergunta. Nem eu mesmo.`,
    },
    {
      title: "O que há para mim? Como isso me ajudará?",
      content: `Apresse-se em criar o seu próprio Avatar - depois convide seus contatos para conversar com você. Imagine conversar com uma centena ou milhares de visitantes simultaneamente - ao mesmo tempo.

    Poder convidar milhares dos seus fãs/seguidores/admiradores para visitar o seu Avatar, fazer qualquer pergunta ao seu Avatar e obter uma resposta instantânea seria de imenso benefício para você se você é um Líder Político / Celebridade.
    
    Você será capaz de criar o seu Avatar fazendo upload das suas muitas horas de discursos (arquivos de áudio) no Personal.ai`,
    },
  ],
  sv: [
    "Vanliga Frågor",
    {
      title: "Vad handlar detta om?",
      content: (
        <p>
          <p>
            Detta är min Digitala Avatar, drivs / betjänas av en API (
            <a
              href="https://www.personal.ai"
              className="faqs-text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal.ai
            </a>
            )
          </p>
          <br />
          <p>Detta är mitt försök att uppnå Virtuell Odödlighet</p>
          <br />
          <p>
            De flesta ÄMNEN - och relevanta SVAR - du ser härstammar från det
            digitala innehållet på{" "}
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              hemenparekh.in
            </a>{" "}
            , inklusive mina bloggar / anteckningar / dikter etc., skrivna från
            1942 och framåt (cirka 30 000+ dokument)
          </p>
          <br />
          <p>
            Den här Digitala Avataren av mig är dedikerad till min fru BHARATI,
            som fortsätter att vårda min Fysiska Avatar sedan 1959.
          </p>
          <br />
          <p>
            Mitt intresse för Artificiell Intelligens (AI) går tillbaka till
            1996, när jag utvecklade logik för NLP i en anteckning riktad till
            mina kollegor.
          </p>
          <br />
          <p>
            Efter att ha läst denna anteckning gav BARD mig följande titel {">"}{" "}
            <a
              href="https://myblogepage.blogspot.com/search?q=grandfather+of+ai"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              Artificiell Intelligens Farfar
            </a>
          </p>
        </p>
      ),
    },
    {
      title: "Vad kan jag göra här?",
      content: `Här kan du chatta med min avatar genom att ställa frågor - nu och långt efter att jag har gått. Du kan starta en chatt genom att fråga:
    "Hej Hemen, vad är dina åsikter om……………"
    Var trygg med din anonymitet. Ingen kommer att veta att du ställde den här frågan. Inte ens jag.`,
    },
    {
      title: "Vad finns det för mig? Hur kommer detta att hjälpa mig?",
      content: `Skynda dig att skapa din egen Avatar - bjud sedan in dina kontakter att chatta med dig. Tänk dig att chatta med hundra eller tusen av dina besökare samtidigt - på samma gång.

    Att kunna bjuda in tusentals av dina fans/följare/beundrare att besöka din Avatar, ställa vilken fråga som helst till din Avatar och få ett omedelbart svar skulle vara av enorm nytta för dig om du är en Politisk Ledare / Kändis.
    
    Du kommer att kunna skapa din Avatar genom att ladda upp dina många timmar av tal (ljudfiler) på Personal.ai`,
    },
  ],
  vi: [
    "Câu Hỏi Thường Gặp",
    {
      title: "Điều này là về cái gì?",
      content: (
        <p>
          <p>
            Đây là Biểu tượng số của tôi, được cung cấp / phục vụ bởi một API (
            <a
              href="https://www.personal.ai"
              className="faqs-text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal.ai
            </a>
            )
          </p>
          <br />
          <p>Đây là cố gắng của tôi để đạt được Bất tử Ảo</p>
          <br />
          <p>
            Hầu hết các CHỦ ĐỀ - và CÂU TRẢ LỜI liên quan - bạn thấy đều xuất
            phát từ nội dung số của{" "}
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              hemenparekh.in
            </a>{" "}
            , bao gồm các blog / ghi chú / thơ của tôi, viết từ năm 1942 trở đi
            (khoảng 30,000+ tài liệu)
          </p>
          <br />
          <p>
            Biểu tượng Số của tôi này được dành cho vợ tôi BHARATI, người tiếp
            tục dinh dưỡng cơ thể Số của tôi từ năm 1959.
          </p>
          <br />
          <p>
            Sự quan tâm của tôi đối với Trí tuệ Nhân tạo (AI) bắt đầu từ năm
            1996, khi tôi phát triển logic cho Xử lý Ngôn ngữ Tự nhiên (NLP)
            trong một ghi chú gửi đồng nghiệp.
          </p>
          <br />
          <p>
            Sau khi đọc ghi chú này, BARD đặt cho tôi danh hiệu sau {">"}{" "}
            <a
              href="https://myblogepage.blogspot.com/search?q=grandfather+of+ai"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              Ông Nội của Trí tuệ Nhân tạo
            </a>
          </p>
        </p>
      ),
    },
    {
      title: "Tôi có thể làm gì ở đây?",
      content: `Ở đây, bạn có thể trò chuyện với avatar của tôi bằng cách đặt câu hỏi - bây giờ và sau khi tôi đã rời đi. Bạn có thể bắt đầu một cuộc trò chuyện bằng cách hỏi:
    "Chào Hemen, ý kiến của bạn về…………… là gì"
    Hãy yên tâm về sự ẩn danh của bạn. Không ai sẽ biết rằng bạn đã đặt câu hỏi này. Ngay cả tôi.`,
    },
    {
      title: "Điều gì đang chờ đợi tôi? Làm thế nào điều này sẽ giúp tôi?",
      content: `Nhanh chóng tạo Avatar của bạn - sau đó mời các liên lạc của bạn tham gia trò chuyện với bạn. Hãy tưởng tượng, trò chuyện với hàng trăm hoặc hàng nghìn khách thăm của bạn đồng thời - cùng một lúc.

    Có khả năng mời hàng nghìn người hâm mộ/theo dõi/người hâm mộ của bạn, đến thăm Avatar của bạn, đặt bất kỳ câu hỏi nào cho Avatar của bạn và nhận câu trả lời ngay lập tức, sẽ mang lại lợi ích to lớn cho bạn nếu bạn là một Nhà lãnh đạo Chính trị / Người nổi tiếng.
    
    Bạn sẽ có khả năng tạo ra Avatar của mình bằng cách tải lên nhiều giờ nói chuyện của bạn (tệp âm thanh) tại Personal.ai`,
    },
  ],
  tr: [
    "Sıkça Sorulan Sorular",
    {
      title: "Bu tam olarak nedir?",
      content: (
        <p>
          <p>
            Bu benim Dijital Avatar'ım, bir API tarafından beslenmiş / hizmet
            edilmiştir (
            <a
              href="https://www.personal.ai"
              className="faqs-text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal.ai
            </a>
            )
          </p>
          <br />
          <p>Bu, Sanal Ölümsüzlük elde etme çabam</p>
          <br />
          <p>
            Gördüğünüz ÇOĞU KONU - ve ilgili CEVAPLAR - dijital içeriğimden
            türetilmiştir{" "}
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              hemenparekh.in
            </a>{" "}
            , yaklaşık olarak 1942'den itibaren yazdığım bloglarımı / notlarımı
            / şiirlerimi içerir (yaklaşık 30.000+ belge)
          </p>
          <br />
          <p>
            Bu Dijital Avatar'ım, 1959'dan beri fiziksel Avatar'ımı besleyen
            eşim BHARATI'ye adanmıştır.
          </p>
          <br />
          <p>
            Yapay Zeka (YZ) ile ilgilenmeye 1996 yılında başladım, NLP için
            mantık geliştirdiğim bir notta.
          </p>
          <br />
          <p>
            Bu notu okuduktan sonra, BARD bana şu unvanı verdi {">"}{" "}
            <a
              href="https://myblogepage.blogspot.com/search?q=grandfather+of+ai"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              Yapay Zeka'nın Dede
            </a>
          </p>
        </p>
      ),
    },
    {
      title: "Burada ne yapabilirim?",
      content: `Burada, avatarımla sorular sorarak benimle sohbet edebilirsiniz - şimdi ve ben gittikten sonra uzun bir süre. Bir sohbet başlatabilirsiniz, şöyle sorarak:
    "Hey Hemen,…………… hakkındaki görüşlerin nelerdir"
    Anonimliğiniz konusunda endişelenmeyin. Bu soruyu sizin sorduğunuzu kimse bilmeyecek. Ben bile.`,
    },
    {
      title: "Benim için burada ne var? Bu bana nasıl yardımcı olacak?",
      content: `Kendi Avatar'ınızı oluşturmak için acele edin - ardından iletişimlerinizi sizinle sohbet etmeye davet edin. Hayal edin, aynı anda yüz veya binlerce ziyaretçinizle sohbet etmek.

    Siyasi bir Lider / Ünlü iseniz, binlerce hayranınızı/takipçinizi/hayranınızı, Avatar'ınızı ziyaret etmeye, Avatar'ınıza herhangi bir soru sormaya ve anında bir cevap alamaya davet edebilmek, büyük fayda sağlar.
    
    Kişisel.ai'de birkaç saatlik konuşmanızı (ses dosyalarınızı) yükleyerek kendi Avatar'ınızı oluşturabileceksiniz`,
    },
  ],
  el: [
    "Συχνές Ερωτήσεις",
    {
      title: "Τι είναι αυτό ακριβώς;",
      content: (
        <p>
          <p>
            Αυτό είναι το Ϩηφικό μου Άβαταρ, το οποίο τροφοδοτείται /
            εξυπηρετείται από μια API (
            <a
              href="https://www.personal.ai"
              className="faqs-text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal.ai
            </a>
            )
          </p>
          <br />
          <p>Αυτή είναι η προσπάθειά μου να επιτύχω τη Ψηφιακή Αθανασία</p>
          <br />
          <p>
            Τα ΠΕΡΙΣΣΟΤΕΡΑ ΘΕΜΑΤΑ - και τα σχετικά ΕΡΩΤΗΜΑΤΑ - που βλέπετε
            προέρχονται από τον ψηφιακό περιεχόμενο του{" "}
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              hemenparekh.in
            </a>{" "}
            , περιλαμβάνοντας τα ιστολόγιά μου / σημειώσεις / ποιήματά μου,
            γραμμένα από το 1942 και μετά (περίπου 30.000+ έγγραφα)
          </p>
          <br />
          <p>
            Αυτό το Ψηφιακό Άβαταρ μου αφιερώνεται στη σύζυγό μου BHARATI, η
            οποία συνεχίζει να τρέφει το Φυσικό μου Άβαταρ από το 1959.
          </p>
          <br />
          <p>
            Το ενδιαφέρον μου για την Τεχνητή Νοημοσύνη (ΤΝ) ξεκινά το 1996,
            όταν ανέπτυξα λογική για τη Φυσική Γλώσσα (NLP) σε μια σημείωση προς
            τους συναδέλφους μου.
          </p>
          <br />
          <p>
            Μετά την ανάγνωση αυτής της σημείωσης, ο BARD μου έδωσε τον παρακάτω
            τίτλο {">"}{" "}
            <a
              href="https://myblogepage.blogspot.com/search?q=grandfather+of+ai"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              Παππούς της Τεχνητής Νοημοσύνης
            </a>
          </p>
        </p>
      ),
    },
    {
      title: "Τι μπορώ να κάνω εδώ;",
      content: `Εδώ μπορείτε να συνομιλήσετε με το άβατάρ μου κάνοντας ερωτήσεις - τώρα και για πολύ καιρό μετά την αποχώρησή μου. Μπορείτε να ξεκινήσετε μια συνομιλία κάνοντας την ερώτηση:
    "Γεια σου Χέμεν, ποιες είναι οι απόψεις σου για……………"
    Μπορείτε να είστε βέβαιοι για την ανωνυμία σας. Κανείς δεν θα μάθει ότι κάνατε αυτήν την ερώτηση. Ούτε κι εγώ.`,
    },
    {
      title: "Τι ωφελίζομαι από αυτό; Πώς θα με βοηθήσει αυτό;",
      content: `Βιαστείτε να δημιουργήσετε το δικό σας άβαταρ - στη συνέχεια, προσκαλέστε τους επαφές σας να συνομιλήσουν μαζί σας. Φανταστείτε να συνομιλείτε με εκατοντάδες ή χιλιάδες επισκέπτες σας ταυτόχρονα - την ίδια στιγμή.

    Το να μπορείτε να προσκαλείτε χιλιάδες θαυμαστές/ακόλουθους/θαυμαστές σας, να επισκεφτούν το άβαταρ σας, να κάνουν οποιαδήποτε ερώτηση στο άβαταρ σας και να λάβουν αμέσως μια απάντηση, θα ήταν απόλυτα ωφέλιμο για εσάς εάν είστε Πολιτικός Ηγέτης / Διάσημος.
    
    Θα μπορείτε να δημιουργήσετε το δικό σας άβαταρ ανεβάζοντας τις πολλές ώρες ομιλίας σας (αρχεία ήχου) στο Personal.ai`,
    },
  ],
  ru: [
    "Часто Задаваемые Вопросы",
    {
      title: "О чем это вообще?",
      content: (
        <p>
          <p>
            Это мой Цифровой Аватар, питаемый / обслуживаемый с помощью API (
            <a
              href="https://www.personal.ai"
              className="faqs-text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal.ai
            </a>
            )
          </p>
          <br />
          <p>Это моя попытка достичь Виртуальной Бессмерти</p>
          <br />
          <p>
            Большинство ТЕМ и соответствующие ОТВЕТЫ, которые вы видите,
            происходят из цифрового контента{" "}
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              hemenparekh.in
            </a>{" "}
            , включая мои блоги / заметки / стихи и т. д., написанные с 1942
            года (примерно 30 000+ документов)
          </p>
          <br />
          <p>
            Этот Цифровой Аватар мой посвящен моей жене БХАРАТИ, которая
            продолжает питать мой Физический Аватар с 1959 года.
          </p>
          <br />
          <p>
            Мой интерес к искусственному интеллекту (ИИ) начался в 1996 году,
            когда я разработал логику для обработки естественного языка (NLP) в
            заметке, адресованной моим коллегам.
          </p>
          <br />
          <p>
            После прочтения этой заметки, BARD присвоил мне следующее звание{" "}
            {">"}{" "}
            <a
              href="https://myblogepage.blogspot.com/search?q=grandfather+of+ai"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              Дедушка искусственного интеллекта
            </a>
          </p>
        </p>
      ),
    },
    {
      title: "Что я могу сделать здесь?",
      content: `Здесь вы можете чатиться с моим аватаром, задавая вопросы - как сейчас, так и долгие годы после моего ухода. Вы можете начать чат, задав вопрос:
    "Привет Хемен, каковы твои взгляды на……………"
    Будьте уверены в своей анонимности. Никто не узнает, что вы задали этот вопрос. Даже я не буду знать.`,
    },
    {
      title: "Что мне это даст? Как это поможет мне?",
      content: `Поторопитесь создать свой собственный Аватар - затем пригласите свои контакты пообщаться с вами. Представьте, как вы общаетесь с сотнями или тысячами ваших посетителей одновременно - в одно и то же время.

    Возможность пригласить тысячи ваших поклонников/подписчиков/поклонителей посетить ваш Аватар, задать вашему Аватару любой вопрос и получить мгновенный ответ будет огромной пользой для вас, если вы Политический Лидер / Знаменитость.
    
    Вы сможете создать свой Аватар, загрузив многие часы ваших выступлений (аудиофайлы) на Personal.ai`,
    },
  ],
  id: [
    "Pertanyaan yang Sering Diajukan",
    {
      title: "Apa ini sebenarnya?",
      content: (
        <p>
          <p>
            Ini adalah Avatar Digital saya, dihidupkan / dilayani oleh API (
            <a
              href="https://www.personal.ai"
              className="faqs-text-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              personal.ai
            </a>
            )
          </p>
          <br />
          <p>Ini adalah upaya saya untuk mencapai Kebesaran Virtual</p>
          <br />
          <p>
            Sebagian besar TOPIK - dan JAWABAN yang relevan - yang Anda lihat
            berasal dari konten digital{" "}
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              hemenparekh.in
            </a>{" "}
            , yang terdiri dari blog / catatan / puisi saya, yang ditulis sejak
            tahun 1942 (sekitar 30.000+ dokumen)
          </p>
          <br />
          <p>
            Avatar Digital saya ini didedikasikan untuk istri saya BHARATI, yang
            terus menyuburkan Avatar Fisik saya sejak tahun 1959.
          </p>
          <br />
          <p>
            Minat saya dalam Kecerdasan Buatan (AI) dimulai pada tahun 1996,
            ketika saya mengembangkan logika untuk Pemrosesan Bahasa Alami (NLP)
            dalam sebuah catatan yang ditujukan kepada rekan-rekan saya.
          </p>
          <br />
          <p>
            Setelah membaca catatan ini, BARD memberi saya gelar berikutnya{" "}
            {">"}{" "}
            <a
              href="https://myblogepage.blogspot.com/search?q=grandfather+of+ai"
              target="_blank"
              rel="noopener noreferrer"
              className="faqs-text-link"
            >
              Kakek Kecerdasan Buatan
            </a>
          </p>
        </p>
      ),
    },
    {
      title: "Apa yang bisa saya lakukan di sini?",
      content: `Di sini Anda dapat berbicara dengan avatar saya dengan bertanya - sekarang dan setelah saya pergi. Anda dapat memulai obrolan dengan bertanya:
    "Hey Hemen, bagaimana pandangan Anda tentang……………"
    Jangan ragu tentang anonimitas Anda. Tidak ada yang akan tahu bahwa Anda mengajukan pertanyaan ini. Bahkan saya tidak tahu.`,
    },
    {
      title: "Apa yang ada untuk saya? Bagaimana ini akan membantu saya?",
      content: `Buru-buru buat Avatar Anda sendiri - lalu undang kontak Anda untuk berbicara dengan Anda. Bayangkan, berbicara dengan ratusan atau ribuan pengunjung Anda secara bersamaan - pada saat yang sama.

    Dapat mengundang ribuan penggemar/pengikut/pemuja Anda, untuk mengunjungi Avatar Anda, mengajukan pertanyaan apa pun kepada Avatar Anda, dan mendapatkan jawaban instan, akan sangat menguntungkan bagi Anda jika Anda seorang Pemimpin Politik / Selebriti.
    
    Anda akan dapat membuat Avatar Anda dengan mengunggah rekaman bicara Anda yang berdurasi banyak jam (file audio) di Personal.ai`,
    },
  ],
};

export default faqs;

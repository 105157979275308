import AnimatedPage from "./AnimatedPage";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Axios from "../Axios";
import SyncLoader from "react-spinners/GridLoader";
import translate from "translate";

function Recentqs({
  setQuestion,
  setAnswer,
  setClearButton,
  setSubmitButton,
  recentQsContent,
  language,
}) {
  translate.engine = "google";
  const [recentlyAskedQuestions, setRecentlyAskedQuestions] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState("recentqs__loader");
  const [translating, setTranslating] = useState(false);

  useEffect(() => {
    console.log(recentQsContent);
  }, [recentQsContent]);

  useEffect(() => {
    const getRecentlyAskedQuestions = async () => {
      setLoading(true);
      setLoader("recentqs__loader");
      const url = "/get_recently_asked_questions/";
      const result = await Axios.get(url);
      setLoading(false);
      if (language === "en" || language === "en-GB") {
        setRecentlyAskedQuestions(result.data.recently_asked_questions);
        setLoader("recentqs__loader--disabled");
      } else {
        setTranslating(true);
        let translatedQuestions = [];
        while (translatedQuestions.length === 0) {
          try {
            const text = result.data.recently_asked_questions;
            const translationPromises = text.map(async (question) => {
              let translatedQuestion;
              if (language === "cmn") {
                translatedQuestion = await translate(question, {
                  from: "en",
                  to: "zh",
                });
              } else {
                translatedQuestion = await translate(question, {
                  from: "en",
                  to: language,
                });
              }
              return translatedQuestion;
            });
            translatedQuestions = await Promise.all(translationPromises);
          } catch {}
        }
        setRecentlyAskedQuestions(
          (recentlyAskedQuestions) => translatedQuestions
        );
        setTranslating(false);
        setLoader("recentqs__loader--disabled");
      }
    };
    getRecentlyAskedQuestions();
  }, []);

  return (
    <AnimatedPage>
      <div className="recentqs__container">
        <h1>{recentQsContent[0]}</h1>
        <p>{recentQsContent[1]}</p>
        {/* <div>
          {translating ? (
            <div className="recent-qs-loader">
              <p>Translating</p>
              <SyncLoader
                className="loader"
                color={"skyblue"}
                loading={translating}
                size={10}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : null}
        </div> */}
        <div className="recentqs__wrapper">
          <div className={loader}>
            {loading ? (
              <div className="recent-qs-loader">
                <p>Loading...</p>
                <SyncLoader
                  className="loader"
                  color={"skyblue"}
                  loading={loading}
                  size={40}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : null}
            {translating ? (
              <div className="recent-qs-loader">
                <p>Translating...</p>
                <SyncLoader
                  className="loader"
                  color={"skyblue"}
                  loading={translating}
                  size={40}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : null}
          </div>
          {recentlyAskedQuestions.map((question, index) => {
            return (
              <div
                key={index}
                className="recentqs__div"
                onClick={(event) => {
                  navigate("/");
                  setQuestion(event.target.innerText);
                  setAnswer("");
                  setClearButton("button__wrapper");
                  setSubmitButton("button__wrapper");
                }}
              >
                {question}
              </div>
            );
          })}
        </div>
      </div>
    </AnimatedPage>
  );
}

export default Recentqs;

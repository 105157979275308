import React from "react";
import { motion } from "framer-motion";
import "../../App.css";
import Backdrop from "../Backdrop/index";

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};

function Modal({ handleClose, topic, questions, setQuestion }) {
  return (
    <Backdrop>
      <motion.div
        className="modal"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="modal__header">
          <button className="modal__close-button" onClick={handleClose}>
            X
          </button>
        </div>
        <h2>{topic}</h2>
        <p>Note: Select a question from the list below to proceed</p>
        {questions.map((question, index) => {
          return (
            <div key={index} className="modal__question" onClick={handleClose}>
              {question}
            </div>
          );
        })}
      </motion.div>
    </Backdrop>
  );
}

export default Modal;

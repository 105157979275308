import React from "react";
import Accordion from "./Accordion";
import AnimatedPage from "./AnimatedPage";

function Faqs({ faqsContent }) {
  return (
    <AnimatedPage>
      <div className="faqs__container">
        <h1>{faqsContent[0]}</h1>
        <Accordion sections={faqsContent.slice(1, faqsContent.length)} />
      </div>
    </AnimatedPage>
  );
}

export default Faqs;

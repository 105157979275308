import React from "react";
import AnimatedPage from "./AnimatedPage";
import FlowDiagram from "../assets/digital-avatar-flow-diagram.png";

function CreateYourAvatar() {
  return (
    <AnimatedPage>
      <div className="create-your-avatar--container">
        <div className="create-your-avatar--text-wrapper">
          <h4>
            This is how my Virtual Avatar is able to answer your spoken
            questions.
          </h4>
          <h4>You too can create your Virtual Avatar. It is easy</h4>
          <br />
          <h4>Consult the following resources:-</h4>
          <h4>
            Founder / CEO of Personal.ai , Suman Kanuganti {">"}{" "}
            <a
              href="https://s.personal.ai"
              target="_blank"
              rel="noopener noreferrer"
              className="anchor"
            >
              s.personal.ai
            </a>
            {" / "}
            <a
              href="mailto:team@personal.ai"
              target="_blank"
              rel="noopener noreferrer"
              className="anchor"
            >
              team@personal.ai
            </a>
          </h4>
          <h4>
            Guidance {">"} Hemen Parekh / Founder{" "}
            <a
              href="https://www.3pconsultants.co.in"
              target="_blank"
              rel="noopener noreferrer"
              className="anchor"
            >
              www.3pconsultants.co.in
            </a>
            {" / "}
            <a
              href="mailto:hcp@recruitguru.com"
              target="_blank"
              rel="noopener noreferrer"
              className="anchor"
            >
              hcp@recruitguru.com
            </a>
            {" / "}
            <a
              href="http://www.hemenparekh.in"
              target="_blank"
              rel="noopener noreferrer"
              className="anchor"
            >
              www.hemenparekh.in
            </a>
          </h4>
          <h4>
            Website Development {">"} Kishan Kokal {"> "}
            <a
              href="mailto:kokalkishan.official@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
              className="anchor"
            >
              kokalkishan.official@gmail.com
            </a>
          </h4>
          <h4>
            Resources {">"} Speech to Text (Web Speech API - inbuilt in Browser)
            / Text to Speech {"> "}
            <a
              href="https://cloud.google.com/text-to-speech"
              target="_blank"
              rel="noopener noreferrer"
              className="anchor"
            >
              cloud.google.com/text-to-speech
            </a>
          </h4>
          <br />
          <img
            src={FlowDiagram}
            alt="digital-avatar-flow-diagram"
            className="flow-diagram-image"
          />
          <br />
          <h4>
            - It is super simple for everyone to create their own Avatar
            (provided they have enough "digital content" to convert into memory
            blocks)
          </h4>
          <h4>
            - It is a much better method of "personal branding / self promotion
            / engaging with followers (specially, for celebrities)"
          </h4>
          <h4>
            - It is certainly a superior way than a simple "Chat Box" (very
            common for most company websites) - where you need a human to
            answer.
          </h4>
        </div>
      </div>
    </AnimatedPage>
  );
}

export default CreateYourAvatar;

import React from "react";
import { motion } from "framer-motion";
import "../../App.css";
import Backdrop from "../Backdrop/index";
import { Link } from "react-router-dom";

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};

function NavModal({ handleClose, navModalOpen }) {
  return (
    <Backdrop>
      <motion.div
        className="navModal"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="modal__header">
          <button className="modal__close-button" onClick={handleClose}>
            X
          </button>
        </div>
        <ul className="modal-nav__link--list">
          <li className="modal-nav__link">
            <Link
              to="/"
              className="modal-nav__link--anchor"
              onClick={handleClose}
            >
              Home
            </Link>
          </li>

          <li className="modal-nav__link">
            <Link
              to="/faqs"
              className="modal-nav__link--anchor"
              onClick={handleClose}
            >
              FAQs
            </Link>
          </li>
          <li className="modal-nav__link">
            <Link
              to="/recently-asked-questions"
              className="modal-nav__link--anchor"
              onClick={handleClose}
            >
              Recently Asked Questions
            </Link>
          </li>
          <li className="modal-nav__link">
            <Link
              to="/stats"
              className="modal-nav__link--anchor"
              onClick={handleClose}
            >
              Statistics
            </Link>
          </li>
          <li className="modal-nav__link" onClick={handleClose}>
            <a
              href="http://www.hemenparekh.in/"
              target="_blank"
              rel="noopener noreferrer"
              className="modal-nav__link--anchor"
            >
              My Blogs
            </a>
          </li>
          <li className="modal-nav__link" onClick={handleClose}>
            <Link
              to="/create-your-own-digital-avatar"
              className="modal-nav__link--anchor"
            >
              Create Your Own Digital Avatar
            </Link>
          </li>
        </ul>
      </motion.div>
    </Backdrop>
  );
}

export default NavModal;

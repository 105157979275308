import React, { useEffect, useState } from "react";
import hcp from "../assets/hcp_2.png";
import "../App.css";
import SyncLoader from "react-spinners/GridLoader";

function Hcp({
  topics,
  value,
  open,
  close,
  setTopic,
  modalOpen,
  topicLoading,
  loader,
}) {
  // Add a state to track animation
  const [animateTopic, setAnimateTopic] = useState(false);
  useEffect(() => {
    // Toggle the animateTopic state to zoom out
    setAnimateTopic(true);

    // Clear the animation after a delay (equal to your transition duration)
    const zoomOutTimeout = setTimeout(() => {
      setAnimateTopic(false);
    }, 300);

    return () => {
      clearTimeout(zoomOutTimeout);
    };
  }, [value]);

  return (
    <div className="hcp__image-container">
      <div
        className={`topic__content ${
          animateTopic ? "zoom-in-out" : "zoom-in-in"
        }`}
      >
        {topicLoading ? (
          <div className={loader}>
            <SyncLoader
              className="loader"
              color={"blue"}
              loading={topicLoading}
              size={10}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : (
          <span
            className="topic__span"
            onClick={() => {
              setTopic(`${topics[value]}`);
              modalOpen ? close() : open();
            }}
          >
            {topics[value]}
          </span>
        )}
      </div>
      <img className="hcp__image" src={hcp} alt="hcp" />
    </div>
  );
}

export default Hcp;

const RecentlyAsked = {
  en: [
    "Recently Asked Questions",
    "Note: Select a question from the list below to proceed",
  ],
  hi: [
    "हाल ही में पूछे गए प्रश्न",
    "नोट: आगे बढ़ने के लिए नीचे सूची से कोई प्रश्न चुनें",
  ],
  gu: [
    "તાજેતરના પ્રશ્નો",
    "નોંધ: આગળ વધવા માટે નીચેના સૂચિમાંથી એક પ્રશ્ન પસંદ કરો",
  ],
  mr: [
    "आत्ताच प्रश्नित केलेले प्रश्न",
    "टीप: आगाऊ जाण्यासाठी खालील यादीतील कोणत्याही प्रश्नावर क्लिक करा",
  ],
  bn: [
    "সাম্প্রতিকভাবে জিজ্ঞাসা হয়েছে",
    "নোট: এগুলি থেকে একটি প্রশ্ন নির্বাচন করতে নীচের তালিকা থেকে যান",
  ],
  te: [
    "ఇటీవల అడగబడిన ప్రశ్నలు",
    "గమనిక: క్రింద పటికి నుంచి ఒక ప్రశ్నను ఎంచుకోండి",
  ],
  ta: [
    "சமீபத்திய கேள்விகள்",
    "குறிப்பு: மேலும் செல்ல கீழே உள்ள பட்டியலில் ஒரு கேள்வியை தேர்ந்தெடுக்கவும்",
  ],
  ka: [
    "ಇತ್ತೀಚಿನ ಪ್ರಶ್ನೆಗಳು",
    "ಗಮನಿಸಿ: ಮುಂದುವರಿಯಲು ಕೆಳಗಿನ ಪಟಕ್ಕೆ ನಿಂತ ಪ್ರಶ್ನೆಯನ್ನು ಆರಿಸಿ",
  ],
  ml: [
    "സ്വന്തമായി ചോദിച്ച ചോദ്യങ്ങൾ",
    "അറിയിക്കുക: തുടരാൻ താഴെയുള്ള പട്ടികയിൽ നിന്ന് ഒന്നു തെരഞ്ഞെടുക്കുക",
  ],
  "en-GB": [
    "Recently Asked Questions",
    "Note: Select a question from the list below to proceed",
  ],
  ar: [
    "الأسئلة المطروحة مؤخرًا",
    "ملحوظة: اختر سؤالًا من القائمة أدناه للمتابعة",
  ],
  cmn: ["最近常问的问题", "注意：从下面的列表中选择一个问题以继续"],
  ja: [
    "最近よく寄せられる質問",
    "注意：進むには以下のリストから質問を選択してください",
  ],
  ms: [
    "Soalan yang Sering Ditanya Baru-baru Ini",
    "Nota: Pilih satu soalan dari senarai di bawah untuk meneruskan",
  ],
  de: [
    "Kürzlich gestellte Fragen",
    "Hinweis: Wählen Sie eine Frage aus der Liste unten aus, um fortzufahren",
  ],
  es: [
    "Preguntas recientes",
    "Nota: Seleccione una pregunta de la lista a continuación para continuar",
  ],
  eu: [
    "Berriki Egindako Galderak",
    "Oharra: Jarraian dagoen zerrendatik galdera bat hautatu ahal izateko",
  ],
  fr: [
    "Questions récemment posées",
    "Note : Sélectionnez une question dans la liste ci-dessous pour continuer",
  ],
  it: [
    "Domande recenti",
    "Nota: Seleziona una domanda dalla lista sottostante per procedere",
  ],
  pt: [
    "Perguntas Feitas Recentemente",
    "Nota: Selecione uma pergunta da lista abaixo para prosseguir",
  ],
  sv: [
    "Nyligen ställda frågor",
    "Observera: Välj en fråga från listan nedan för att fortsätta",
  ],
  vi: [
    "Câu hỏi được Đặt Gần Đây",
    "Lưu ý: Chọn một câu hỏi từ danh sách dưới đây để tiếp tục",
  ],
  tr: [
    "Son Sorulan Sorular",
    "Not: Devam etmek için aşağıdaki listeden bir soru seçin",
  ],
  el: [
    "Πρόσφατα Κομμένες Ερωτήσεις",
    "Σημείωση: Επιλέξτε μια ερώτηση από την παρακάτω λίστα για να προχωρήσετε",
  ],
  ru: [
    "Недавно заданные вопросы",
    "Примечание: Выберите вопрос из списка ниже, чтобы продолжить",
  ],
  id: [
    "Pertanyaan Terbaru",
    "Catatan: Pilih pertanyaan dari daftar di bawah untuk melanjutkan",
  ],
};

export default RecentlyAsked;

const Stats = {
  en: [
    "Statistics",
    "Total No. of visitors",
    "Online Visitors",
    "Total Questions",
    "Total Topics",
    "No. of Visitors Yesterday",
    "No. of Visitors Today",
  ],
  hi: [
    "सांख्यिकी",
    "कुल आगंतुकों की संख्या",
    "ऑनलाइन आगंतुक",
    "कुल प्रश्न",
    "कुल विषय",
    "गतिकाल के आगंतुकों की संख्या",
    "आज के आगंतुकों की संख्या",
  ],
  gu: [
    "આંકડા",
    "મુલાકાતીઓની કુલ સંખ્યા",
    "ઓનલાઈન મુલાકાતીઓ",
    "કુલ પ્રશ્નો",
    "કુલ વિષયો",
    "ગઈકાલે મુલાકાતીઓની સંખ્યા",
    "આજે મુલાકાતીઓની સંખ્યા",
  ],
  mr: [
    "सांख्यिकी",
    "एकूण अभ्यागतांची संख्या",
    "ऑनलाइन अभ्यागत",
    "एकूण प्रश्न",
    "एकूण विषय",
    "कालच्या अभ्यागतांची संख्या",
    "आजच्या अभ्यागतांची संख्या",
  ],
  bn: [
    "পরিসংখ্যান",
    "মোট দর্শক সংখ্যা",
    "অনলাইন ভিজিটর",
    "মোট প্রশ্ন",
    "মোট বিষয়",
    "গতকাল দর্শনার্থীদের সংখ্যা",
    "আজকের দর্শনার্থীদের সংখ্যা",
  ],
  te: [
    "గణాంకాలు",
    "మొత్తం సందర్శకుల సంఖ్య",
    "ఆన్‌లైన్ సందర్శకులు",
    "మొత్తం ప్రశ్నలు",
    "మొత్తం అంశాలు",
    "నిన్న సందర్శకుల సంఖ్య",
    "ఈరోజు సందర్శకుల సంఖ్య",
  ],
  ta: [
    "புள்ளிவிவரங்கள்",
    "பார்வையாளர்களின் மொத்த எண்ணிக்கை",
    "ஆன்லைன் பார்வையாளர்கள்",
    "மொத்த கேள்விகள்",
    "மொத்த தலைப்புகள்",
    "நேற்று வருகை தந்தவர்களின் எண்ணிக்கை",
    "இன்று வருகை தந்தவர்களின் எண்ணிக்கை",
  ],
  ka: [
    "ಅಂಕಿಅಂಶ",
    "ಸಂದರ್ಶಕರ ಒಟ್ಟು ಸಂಖ್ಯೆ",
    "ಆನ್‌ಲೈನ್ ಸಂದರ್ಶಕರು",
    "ಒಟ್ಟು ಪ್ರಶ್ನೆಗಳು",
    "ಒಟ್ಟು ವಿಷಯಗಳು",
    "ನಿನ್ನೆ ಭೇಟಿ ನೀಡಿದವರ ಸಂಖ್ಯೆ",
    "ಇಂದು ಸಂದರ್ಶಕರ ಸಂಖ್ಯೆ",
  ],
  ml: [
    "സ്ഥിതിവിവരക്കണക്കുകൾ",
    "സന്ദർശകരുടെ ആകെ എണ്ണം",
    "ഓൺലൈൻ സന്ദർശകർ",
    "ആകെ ചോദ്യങ്ങൾ",
    "മൊത്തം വിഷയങ്ങൾ",
    "ഇന്നലെ സന്ദർശകരുടെ എണ്ണം",
    "ഇന്നത്തെ സന്ദർശകരുടെ എണ്ണം",
  ],
  "en-GB": [
    "Statistics",
    "Total No. of visitors",
    "Online Visitors",
    "Total Questions",
    "Total Topics",
    "No. of Visitors Yesterday",
    "No. of Visitors Today",
  ],
  ar: [
    "إحصائيات",
    "إجمالي عدد الزوار",
    "زوار الانترنت",
    "مجموع الأسئلة",
    "مجموع المواضيع",
    "عدد الزوار أمس",
    "عدد الزوار اليوم",
  ],
  cmn: [
    "统计数据",
    "访客总数",
    "在线访客",
    "总问题",
    "总主题",
    "昨天的访客数量",
    "今日访客数量",
  ],
  ja: [
    "統計",
    "訪問者総数",
    "オンライン訪問者",
    "総質問数",
    "総トピック数",
    "昨日の訪問者数",
    "今日の訪問者数",
  ],
  ms: [
    "Statistik",
    "Jumlah Bilangan Pelawat",
    "Pelawat Dalam Talian",
    "Jumlah Soalan",
    "Jumlah Topik",
    "Bilangan Pelawat Semalam",
    "Bilangan Pelawat Hari Ini",
  ],
  de: [
    "Statistiken",
    "Gesamtanzahl der Besucher",
    "Online-Besucher",
    "Gesamtanzahl der Fragen",
    "Gesamtanzahl der Themen",
    "Anzahl der Besucher gestern",
    "Anzahl der Besucher heute",
  ],
  es: [
    "Estadísticas",
    "Total de visitantes",
    "Visitantes en línea",
    "Total de preguntas",
    "Total de temas",
    "Número de visitantes ayer",
    "Número de visitantes hoy",
  ],
  eu: [
    "Estatistikak",
    "Bisitarien Zenbaki Guztira",
    "Online bisitariak",
    "Guztira egindako galderak",
    "Guztira egindako gaia",
    "Atzo bisitatutako bisitarien zenbaki",
    "Gaurko bisitarien zenbaki",
  ],
  fr: [
    "Statistiques",
    "Nombre total de visiteurs",
    "Visiteurs en ligne",
    "Total des questions",
    "Total des sujets",
    "Nombre de visiteurs hier",
    "Nombre de visiteurs aujourd'hui",
  ],
  it: [
    "Statistiche",
    "Numero totale di visitatori",
    "Visitatori online",
    "Totale delle domande",
    "Totale degli argomenti",
    "Numero di visitatori ieri",
    "Numero di visitatori oggi",
  ],
  pt: [
    "Estatísticas",
    "Número total de visitantes",
    "Visitantes online",
    "Total de perguntas",
    "Total de tópicos",
    "Número de visitantes ontem",
    "Número de visitantes hoje",
  ],
  sv: [
    "Statistik",
    "Totalt antal besökare",
    "Online-besökare",
    "Totalt antal frågor",
    "Totalt antal ämnen",
    "Antal besökare igår",
    "Antal besökare idag",
  ],
  vi: [
    "Thống kê",
    "Tổng số lượt truy cập",
    "Người truy cập trực tuyến",
    "Tổng số câu hỏi",
    "Tổng số chủ đề",
    "Số lượt truy cập ngày hôm qua",
    "Số lượt truy cập ngày hôm nay",
  ],
  tr: [
    "İstatistikler",
    "Toplam Ziyaretçi Sayısı",
    "Çevrimiçi Ziyaretçiler",
    "Toplam Soru Sayısı",
    "Toplam Konu Sayısı",
    "Dünki Ziyaretçi Sayısı",
    "Bugünkü Ziyaretçi Sayısı",
  ],
  el: [
    "Στατιστικά",
    "Συνολικός Αριθμός Επισκεπτών",
    "Επισκέπτες Online",
    "Συνολικός Αριθμός Ερωτήσεων",
    "Συνολικός Αριθμός Θεμάτων",
    "Αριθμός Επισκεπτών Χθες",
    "Αριθμός Επισκεπτών Σήμερα",
  ],
  ru: [
    "Статистика",
    "Общее количество посетителей",
    "Посетители онлайн",
    "Общее количество вопросов",
    "Общее количество тем",
    "Количество посетителей вчера",
    "Количество посетителей сегодня",
  ],
  id: [
    "Statistik",
    "Total Jumlah Pengunjung",
    "Pengunjung Online",
    "Total Pertanyaan",
    "Total Topik",
    "Jumlah Pengunjung Kemarin",
    "Jumlah Pengunjung Hari Ini",
  ],
};

export default Stats;

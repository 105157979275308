const Home = {
  en: [
    "Type your own question or click on any of the topic appearing in the thought bubble. Prefer not to type? Simply click on the microphone icon and speak.",
    "Ask your question - what - why - where - when - how -why",
    "Submit",
    "Clear",
    "Share this answer on WhatsApp",
    "Select Language",
    "Selected Language: ",
    "Answer",
    "Online Visitors: ",
    "Online Visitors: ",
  ],
  hi: [
    "अपना सवाल टाइप करें या विचार बुबल में दिखाई जाने वाले किसी भी विषय पर क्लिक करें। टाइप करना नहीं चाहते? बस माइक्रोफोन आइकन पर क्लिक करें और बोलें।",
    "अपना सवाल पूछें - क्या - क्यों - कहाँ - कब - कैसे - क्यों",
    "सबमिट",
    "साफ़ करें",
    "इस उत्तर को WhatsApp पर साझा करें",
    "भाषा चुनें",
    "चयनित भाषा: ",
    "उत्तर",
    "ऑनलाइन आगंतुकों: ",
    "ऑनलाइन आगंतुकों: ",
  ],
  mr: [
    "आपला प्रश्न टाइप करा किंवा विचार बबलमध्ये दिसणार्‍या कोणत्याही विषयावर क्लिक करा. टाइप करणे नाही असेल तर फक्त मायक्रोफोन आयकॉनवर क्लिक करा आणि बोला.",
    "आपला प्रश्न प्रश्न करा - काय - का - कुठे - कधी - कसे - का",
    "सबमिट करा",
    "साफ करा",
    "या उत्तराचा WhatsApp वर सामायिक करा",
    "भाषा निवडा",
    "निवडलेली भाषा: ",
    "उत्तर",
    "ऑनलाइन आगंतुक: ",
    "ऑनलाइन आगंतुक: ",
  ],
  gu: [
    "તમારો પ્રશ્ન ટાઇપ કરો અથવા વિચાર બબલ પર આવતા કોઈપણ વિષય પર ક્લિક કરો. ટાઇપ કરવું નહીં હોય તો ફક્ત માયક્રોફોન આયકોન પર ક્લિક કરો અને બોલો.",
    "તમારો પ્રશ્ન પૂછો - શું - કેવી રીતે - ક્યાં - ક્યારે - કેવી રીતે - કેમ",
    "સબમિટ કરો",
    "સાફ કરો",
    "આ જવાબને WhatsApp પર શેર કરો",
    "ભાષા પસંદ કરો",
    "પસંદ કરેલી ભાષા: ",
    "જવાબ",
    "ઓનલાઇન મુલાકાતો: ",
    "ઓનલાઇન મુલાકાતો: ",
  ],
  bn: [
    "আপনার প্রশ্ন টাইপ করুন অথবা চিন্তা বুবলে দেখা যাচ্ছে যে কোনও বিষয়ে ক্লিক করুন। টাইপ করতে চান না? কেবল মাইক্রোফোন আইকনে ক্লিক করুন এবং বলুন।",
    "আপনার প্রশ্ন করুন - কি - কেন - কোথায় - কখন - কিভাবে - কেন",
    "জমা দিন",
    "পরিষ্কার",
    "এই উত্তরটি ওয়াটসঅ্যাপে শেয়ার করুন",
    "ভাষা নির্বাচন করুন",
    "নির্বাচিত ভাষা: ",
    "উত্তর",
    "অনলাইন পরিদর্শকগণ: ",
    "অনলাইন পরিদর্শকগণ: ",
  ],
  te: [
    "మీ స్వయం ప్రశ్నను టైప్ చేసి లేదా ఆలోచన బబిల్లో కనబడుతున్న ఏదైనా విషయాన్ని క్లిక్ చేయండి. టైప్ చేసలేదుకున్నా? మైక్ యాకాన్ మీద క్లిక్ చేసి మాట్లాడండి.",
    "మీ ప్రశ్నం - ఏమి - మరియుకోవటి - ఎక్కడ - ఎప్పటికి - ఎలా - ఏమి కాదు",
    "సమర్పించు",
    "తనిఖీ చేయి",
    "ఈ సమాధానం వాట్సాప్ప్‌లో షేర్ చేయండి",
    "భాషను ఎంచుకోండి",
    "ఎంచుకున్న భాష: ",
    "సమాధానం",
    "ఆన్‌లైన్ సందర్శకులు: ",
    "ఆన్‌లైన్ సందర్శకులు: ",
  ],
  ta: [
    "உங்கள் சொந்தக் கேள்வியைத் தட்டச்சு செய்யவும் அல்லது சிந்தனைக் குமிழியில் தோன்றும் தலைப்பில் ஏதேனும் ஒன்றைக் கிளிக் செய்யவும். தட்டச்சு செய்வதை விரும்புகிறீர்களா? மைக்ரோஃபோன் ஐகானைக் கிளிக் செய்து பேசவும்.",
    "உங்கள் கேள்வியைக் கேளுங்கள் - என்ன - ஏன் - எங்கே - எப்போது - எப்படி - ஏன்",
    "சமர்ப்பி",
    "தெளிவு",
    "இந்த பதிலை வாட்ஸ்அப்பில் பகிரவும்",
    "மொழியை தேர்ந்தெடுங்கள்",
    "தேர்ந்தெடுக்கப்பட்ட மொழி: ",
    "பதில்",
    "ஆன்லைன் பார்வையாளர்கள்: ",
    "ஆன்லைன் பார்வையாளர்கள்: ",
  ],
  ka: [
    "ನಿಮ್ಮ ಸ್ವಂತ ಪ್ರಶ್ನೆಯನ್ನು ಟೈಪ್ ಮಾಡಿ ಅಥವಾ ಆಲೋಚನಾ ಬಬಲ್‌ನಲ್ಲಿ ಗೋಚರಿಸುವ ಯಾವುದೇ ವಿಷಯದ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ. ಟೈಪ್ ಮಾಡಲು ಆದ್ಯತೆ ನೀಡುವುದಿಲ್ಲವೇ? ಮೈಕ್ರೊಫೋನ್ ಐಕಾನ್ ಮೇಲೆ ಕ್ಲಿಕ್ ಮಾಡಿ ಮತ್ತು ಮಾತನಾಡಿ.",
    "ನಿಮ್ಮ ಪ್ರಶ್ನೆಯನ್ನು ಕೇಳಿ - ಏನು - ಏಕೆ - ಎಲ್ಲಿ - ಯಾವಾಗ - ಹೇಗೆ - ಏಕೆ",
    "ಸಲ್ಲಿಸು",
    "ಸ್ಪಷ್ಟ",
    "ಈ ಉತ್ತರವನ್ನು WhatsApp ನಲ್ಲಿ ಹಂಚಿಕೊಳ್ಳಿ",
    "ಭಾಷೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
    "ಆಯ್ಕೆಮಾಡಿದ ಭಾಷೆ:",
    "ಉತ್ತರ",
    "ಆನ್‌ಲೈನ್ ಸಂದರ್ಶಕರು:",
    "ಆನ್‌ಲೈನ್ ಸಂದರ್ಶಕರು:",
  ],
  ml: [
    "നിങ്ങളുടെ സ്വന്തം ചോദ്യം ടൈപ്പുചെയ്യുക അല്ലെങ്കിൽ ചിന്താ ബബിളിൽ ദൃശ്യമാകുന്ന ഏതെങ്കിലും വിഷയത്തിൽ ക്ലിക്കുചെയ്യുക. ടൈപ്പ് ചെയ്യുന്നില്ലേ? മൈക്രോഫോൺ ഐക്കണിൽ ക്ലിക്ക് ചെയ്ത് സംസാരിക്കുക.",
    "നിങ്ങളുടെ ചോദ്യം ചോദിക്കുക - എന്ത് - എന്തുകൊണ്ട് - എവിടെ - എപ്പോൾ - എങ്ങനെ - എന്തുകൊണ്ട്",
    "സമർപ്പിക്കുക",
    "വ്യക്തം",
    "ഈ ഉത്തരം WhatsApp-ൽ പങ്കിടുക",
    "ഭാഷ തിരഞ്ഞെടുക്കുക",
    "തിരഞ്ഞെടുത്ത ഭാഷ: ",
    "ഉത്തരം",
    "ഓൺലൈൻ സന്ദർശകർ: ",
    "ഓൺലൈൻ സന്ദർശകർ: ",
  ],
  "en-GB": [
    "Type your own question or click on any of the topic appearing in the thought bubble. Prefer not to type? Simply click on the microphone icon and speak.",
    "Ask your question - what - why - where - when - how -why",
    "Submit",
    "Clear",
    "Share this answer on WhatsApp",
    "Select Language",
    "Selected Language: ",
    "Answer",
    "Online Visitors: ",
    "Online Visitors: ",
  ],
  ar: [
    "اكتب سؤالك الخاص أو انقر فوق أي من المواضيع المظهرة في فقاعة الفكر. تفضل بعدم الكتابة؟ انقر ببساطة على أيقونة الميكروفون وتحدث.",
    "اسأل سؤالك - ماذا - لماذا - أين - متى - كيف - لماذا",
    "إرسال",
    "مسح",
    "شارك هذا الجواب على واتساب",
    "اختر اللغة",
    "اللغة المحددة: ",
    "الجواب",
    "الزوار عبر الإنترنت: ",
    "الزوار عبر الإنترنت: ",
  ],
  cmn: [
    "输入您自己的问题或单击出现在思维泡泡中的任何主题。不想打字？只需单击麦克风图标并说话。",
    "提出你的问题 - 什么 - 为什么 - 在哪里 - 何时 - 如何 - 为什么",
    "提交",
    "清除",
    "在WhatsApp上分享这个答案",
    "选择语言",
    "选择的语言：",
    "回答",
    "在线访客：",
    "在线访客：",
  ],
  ja: [
    "自分の質問を入力するか、思考バブルに表示されるトピックのいずれかをクリックしてください。入力を避けたい場合は、マイクアイコンをクリックして話すだけです。",
    "質問してください - なぜ - どこで - いつ - どのように - なぜ",
    "提出する",
    "クリア",
    "この回答をWhatsAppで共有",
    "言語を選択",
    "選択された言語: ",
    "回答",
    "オンライン訪問者数: ",
    "オンライン訪問者数: ",
  ],
  ms: [
    "Taipkan soalan anda sendiri atau klik pada mana-mana topik yang muncul dalam buih fikiran. Tidak mahu menaip? Klik pada ikon mikrofon dan bercakap.",
    "Tanyakan soalan anda - apa - mengapa - di mana - bila - bagaimana - kenapa",
    "Hantar",
    "Kosongkan",
    "Kongsi jawapan ini di WhatsApp",
    "Pilih Bahasa",
    "Bahasa Terpilih: ",
    "Jawapan",
    "Pengunjung Online: ",
    "Pengunjung Online: ",
  ],
  de: [
    "Tippen Sie Ihre eigene Frage ein oder klicken Sie auf eines der Themen, die in der Gedankenblase erscheinen. Möchten Sie nicht tippen? Klicken Sie einfach auf das Mikrofonsymbol und sprechen Sie.",
    "Stellen Sie Ihre Frage - was - warum - wo - wann - wie - warum",
    "Einreichen",
    "Löschen",
    "Diese Antwort auf WhatsApp teilen",
    "Sprache auswählen",
    "Ausgewählte Sprache: ",
    "Antwort",
    "Online-Besucher: ",
    "Online-Besucher: ",
  ],
  es: [
    "Escribe tu propia pregunta o haz clic en cualquier tema que aparezca en la burbuja de pensamiento. ¿Prefieres no escribir? Simplemente haz clic en el icono del micrófono y habla.",
    "Haz tu pregunta - qué - por qué - dónde - cuándo - cómo - por qué",
    "Enviar",
    "Limpiar",
    "Compartir esta respuesta en WhatsApp",
    "Seleccionar Idioma",
    "Idioma Seleccionado: ",
    "Respuesta",
    "Visitantes en línea: ",
    "Visitantes en línea: ",
  ],
  eu: [
    "Idatzi zure galdera edo sakatu burbuiletan agertzen den gai batean. Ez baduzu idatzi nahi, sakatu mikrofono ikonoa eta hitz egin.",
    "Zu galdera egin - zer - zergatik - non - noiz - nola - zergatik",
    "Bidali",
    "Garbitu",
    "Erantzuna partekatu WhatsApp-en",
    "Hautatu Hizkuntza",
    "Hautatutako Hizkuntza: ",
    "Erantzuna",
    "Online bisitariak: ",
    "Online bisitariak: ",
  ],
  fr: [
    "Saisissez votre propre question ou cliquez sur l'un des sujets apparaissant dans la bulle de pensée. Préférez-vous ne pas taper ? Cliquez simplement sur l'icône du microphone et parlez.",
    "Posez votre question - quoi - pourquoi - où - quand - comment - pourquoi",
    "Soumettre",
    "Effacer",
    "Partager cette réponse sur WhatsApp",
    "Sélectionner la langue",
    "Langue sélectionnée : ",
    "Réponse",
    "Visiteurs en ligne : ",
    "Visiteurs en ligne : ",
  ],
  it: [
    "Digita la tua domanda o clicca su uno dei temi che appare nella nuvoletta del pensiero. Preferisci evitare la digitazione? Clicca semplicemente sull'icona del microfono e parla.",
    "Fai la tua domanda - cosa - perché - dove - quando - come - perché",
    "Invia",
    "Cancella",
    "Condividi questa risposta su WhatsApp",
    "Seleziona la lingua",
    "Lingua selezionata: ",
    "Risposta",
    "Visitatori Online: ",
    "Visitatori Online: ",
  ],
  pt: [
    "Digite sua própria pergunta ou clique em qualquer tópico que apareça na nuvem de pensamento. Prefere não digitar? Basta clicar no ícone do microfone e falar.",
    "Faça sua pergunta - o quê - por quê - onde - quando - como - por quê",
    "Enviar",
    "Limpar",
    "Compartilhar esta resposta no WhatsApp",
    "Selecionar Idioma",
    "Idioma Selecionado: ",
    "Resposta",
    "Visitantes Online: ",
    "Visitantes Online: ",
  ],
  sv: [
    "Skriv din egen fråga eller klicka på något av ämnena som visas i tankebubblan. Föredrar du att inte skriva? Klicka helt enkelt på mikrofonikonen och tala.",
    "Ställ din fråga - vad - varför - var - när - hur - varför",
    "Skicka",
    "Rensa",
    "Dela detta svar på WhatsApp",
    "Välj språk",
    "Valt språk: ",
    "Svar",
    "Online-besökare: ",
    "Online-besökare: ",
  ],
  vi: [
    "Nhập câu hỏi của bạn hoặc nhấp vào bất kỳ chủ đề nào xuất hiện trong bọt suy nghĩ. Không muốn gõ? Đơn giản nhấp vào biểu tượng micro và nói.",
    "Đặt câu hỏi của bạn - cái gì - tại sao - ở đâu - khi nào - làm thế nào - tại sao",
    "Gửi",
    "Xóa",
    "Chia sẻ câu trả lời này trên WhatsApp",
    "Chọn Ngôn ngữ",
    "Ngôn ngữ Đã chọn: ",
    "Trả lời",
    "Người trực tuyến: ",
    "Người trực tuyến: ",
  ],

  tr: [
    "Kendi sorunuzu yazın veya düşünce balonunda görünen herhangi bir konuya tıklayın. Yazmak istemiyor musunuz? Basitçe mikrofon simgesine tıklayın ve konuşun.",
    "Sorunuzu sorun - ne - neden - nere - ne zaman - nasıl - neden",
    "Gönder",
    "Temizle",
    "Bu cevabı WhatsApp'ta paylaş",
    "Dil Seç",
    "Seçilen Dil: ",
    "Cevap",
    "Çevrimiçi Ziyaretçiler: ",
    "Çevrimiçi Ziyaretçiler: ",
  ],
  el: [
    "Πληκτρολογήστε τη δική σας ερώτηση ή κάντε κλικ σε οποιοδήποτε από τα θέματα που εμφανίζονται στο σκέψη φυσαλίδα. Δεν θέλετε να πληκτρολογήσετε; Απλά κάντε κλικ στο εικονίδιο του μικροφώνου και μιλήστε.",
    "Κάντε την ερώτησή σας - τι - γιατί - πού - πότε - πώς - γιατί",
    "Υποβολή",
    "Καθαρισμός",
    "Κοινοποιήστε αυτήν την απάντηση στο WhatsApp",
    "Επιλογή Γλώσσας",
    "Επιλεγμένη Γλώσσα: ",
    "Απάντηση",
    "Διαδικτυακοί Επισκέπτες: ",
    "Διαδικτυακοί Επισκέπτες: ",
  ],
  ru: [
    "Введите свой собственный вопрос или нажмите на любую из тем, появляющихся в облачке мысли. Предпочитаете не печатать? Просто нажмите на значок микрофона и говорите.",
    "Задайте свой вопрос - что - почему - где - когда - как - почему",
    "Отправить",
    "Очистить",
    "Поделиться этим ответом в WhatsApp",
    "Выбрать язык",
    "Выбранный язык: ",
    "Ответ",
    "Посетителей онлайн: ",
    "Посетителей онлайн: ",
  ],
  id: [
    "Ketik pertanyaan Anda sendiri atau klik pada topik yang muncul di balon pikiran. Tidak ingin mengetik? Cukup klik ikon mikrofon dan bicara.",
    "Ajukan pertanyaan Anda - apa - mengapa - di mana - kapan - bagaimana - mengapa",
    "Kirim",
    "Bersihkan",
    "Bagikan jawaban ini di WhatsApp",
    "Pilih Bahasa",
    "Bahasa yang Dipilih: ",
    "Jawaban",
    "Pengunjung Online: ",
    "Pengunjung Online: ",
  ],
};

export default Home;

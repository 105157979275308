import React, { useState } from "react";
import "../App.css";

const AccordionSection = ({
  section,
  isActiveSection,
  setActiveIndex,
  activeIndex,
  sectionIndex,
}) => {
  const toggleSection = () => {
    const nextIndex = isActiveSection ? null : sectionIndex;
    setActiveIndex(nextIndex);
  };
  return (
    <div className="faqs__accordian-container" onClick={toggleSection}>
      <div className="faqs__accordian-title">
        <h3>{section.title}</h3>
        <h3>{isActiveSection ? "-" : "+"}</h3>
      </div>
      <div
        className={
          isActiveSection
            ? "faqs__accordian-content show"
            : "faqs__accordian-content"
        }
      >
        {section.content}
      </div>
    </div>
  );
};

function Accordion({ sections }) {
  const [activeIndex, setActiveIndex] = useState(0);
  return (
    <div className="faqs__accordian">
      {sections.map((section, index) => (
        <AccordionSection
          section={section}
          key={index}
          isActiveSection={index === activeIndex}
          setActiveIndex={setActiveIndex}
          activeIndex={activeIndex}
          sectionIndex={index}
        />
      ))}
    </div>
  );
}

export default Accordion;

import React from "react";
import AnimatedPage from "./AnimatedPage";

function Stats({
  totalQuestions,
  visitors,
  questionsRated,
  avgRating,
  avgQuestionsPerVisitor,
  totalVisitors,
  totalQuestionsInDB,
  totalTopicsInDB,
  visitorsYesterday,
  visitorsToday,
  statsContent,
  languageCounters,
}) {
  const languages = {
    en: "🇮🇳 English",
    hi: "🇮🇳 Hindi",
    gu: "🇮🇳 Gujarati",
    mr: "🇮🇳 Marathi",
    bn: "🇮🇳 Bengali",
    te: "🇮🇳 Telugu",
    ta: "🇮🇳 Tamil",
    kn: "🇮🇳 Kannada",
    ml: "🇮🇳 Malayalam",
    ar: "Arabic",
    cmn: "🇨🇳 Chinese",
    ja: "🇯🇵 Japanese",
    ms: "🇲🇾 Malay",
    de: "🇩🇪 German",
    "en-GB": "🇬🇧 English",
    es: "🇪🇸 Spanish",
    eu: "🇪🇸 Basque",
    fr: "🇫🇷 French",
    it: "🇮🇹 Italian",
    pt: "🇵🇹 Portuguese",
    sv: "🇸🇪 Swedish",
    vi: "🇻🇳 Vietnamese",
    tr: "🇹🇷 Turkish",
    el: "🇬🇷 Greek",
    ru: "🇷🇺 Russian",
    id: "🇮🇩 Indonesian",
  };
  return (
    <AnimatedPage>
      <div className="stats__container">
        <div className="stats__header">
          <h1>{statsContent[0]}</h1>
        </div>
        <div className="stats__wrapper">
          <div className="stats__card">
            <h3 className="stats__content">{statsContent[1]}</h3>
            <h1>
              <span className="stats__numeral">{totalVisitors}</span>
            </h1>
          </div>
          <div className="stats__card">
            <h3 className="stats__content">{statsContent[2]}</h3>
            <h1>
              <span className="stats__numeral">{visitors}</span>
            </h1>
          </div>
          {/* <div className="stats__card">
            <h3 className="stats__content">Total Questions Asked</h3>
            <h1>
              <span className="stats__numeral">{totalQuestions}</span>
            </h1>
          </div>
          <div className="stats__card">
            <h3 className="stats__content">Average Questions / Visitor</h3>
            <h1>
              <span className="stats__numeral">{avgQuestionsPerVisitor}</span>
            </h1>
          </div> */}
          {/* <div className="stats__card">
            <h3 className="stats__content">Questions Rated</h3>
            <h1>
              <span className="stats__numeral">{questionsRated}</span>
            </h1>
          </div>
          <div className="stats__card">
            <h3 className="stats__content">Average Rating</h3>
            <h1>
              <span className="stats__numeral">{avgRating}</span>
            </h1>
          </div> */}
          <div className="stats__card">
            <h3 className="stats__content">{statsContent[3]}</h3>
            <h1>
              <span className="stats__numeral">{totalQuestionsInDB}</span>
            </h1>
          </div>
          <div className="stats__card">
            <h3 className="stats__content">{statsContent[4]}</h3>
            <h1>
              <span className="stats__numeral">{totalTopicsInDB}</span>
            </h1>
          </div>
          <div className="stats__card">
            <h3 className="stats__content">{statsContent[5]}</h3>
            <h1>
              <span className="stats__numeral">{visitorsYesterday}</span>
            </h1>
          </div>
          <div className="stats__card">
            <h3 className="stats__content">{statsContent[6]}</h3>
            <h1>
              <span className="stats__numeral">{visitorsToday}</span>
            </h1>
          </div>
          <div className="stats__card">
            <h3 className="stats__content">
              Frequency of languages chosen by visitors
            </h3>
            <div className="stats__card--inner-container">
              <div className="stats__languages">
                {languageCounters.map((lang, index) => {
                  return (
                    <div key={index} className="stats__content">
                      <span>
                        {languages[lang["language"]]} : {lang["count"]}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AnimatedPage>
  );
}

export default Stats;

import React from "react";
import { motion } from "framer-motion";
import "../../App.css";
import Backdrop from "../Backdrop/index";
import en from "../../assets/greetMessages/en.mp3";
import hi from "../../assets/greetMessages/hi.mp3";
import gu from "../../assets/greetMessages/gu.mp3";
import mr from "../../assets/greetMessages/mr.mp3";
import bn from "../../assets/greetMessages/bn.mp3";
import te from "../../assets/greetMessages/te.mp3";
import ta from "../../assets/greetMessages/ta.mp3";
import kn from "../../assets/greetMessages/kn.mp3";
import ml from "../../assets/greetMessages/ml.mp3";
import ar from "../../assets/greetMessages/ar.mp3";
import cmn from "../../assets/greetMessages/cmn.mp3";
import ja from "../../assets/greetMessages/ja.mp3";
import ms from "../../assets/greetMessages/ms.mp3";
import de from "../../assets/greetMessages/de.mp3";
import es from "../../assets/greetMessages/es.mp3";
import eu from "../../assets/greetMessages/eu.mp3";
import fr from "../../assets/greetMessages/fr.mp3";
import it from "../../assets/greetMessages/it.mp3";
import pt from "../../assets/greetMessages/pt.mp3";
import sv from "../../assets/greetMessages/sv.mp3";
import vi from "../../assets/greetMessages/vi.mp3";
import tr from "../../assets/greetMessages/tr.mp3";
import el from "../../assets/greetMessages/el.mp3";
import ru from "../../assets/greetMessages/ru.mp3";
import id from "../../assets/greetMessages/id.mp3";
import Axios from "../../Axios";

const dropIn = {
  hidden: {
    y: "-100vh",
    opacity: 0,
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      damping: 25,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};

function LanguageModal({
  handleClose,
  languageModalOpen,
  setLanguage,
  greet,
  setGreet,
}) {
  const play = (sound) => {
    new Audio(sound).play();
  };
  
  const updateLanguageCounter = (language) => {
    const url = "/increment_language_counter/";
    const data = {
      language: language,
    };
    Axios.post(url, data);
  };

  return (
    <Backdrop>
      <motion.div
        className="modal"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {!greet && (
          <div className="modal__header">
            <button className="modal__close-button" onClick={handleClose}>
              X
            </button>
          </div>
        )}
        {greet && (
          <h4 className="greet">
            You will be able to ask me questions (by typing or by talking) in
            the language you select. I will reply to you (also in my own voice)
            in that language.
          </h4>
        )}
        <h4>🇮🇳 Indian Languages</h4>
        <div className="language-modal__body">
          <div
            className="language__button"
            onClick={() => {
              updateLanguageCounter("en");
              setLanguage("en");
              greet && play(en);
              handleClose();
            }}
          >
            English
          </div>
          <div
            className="language__button"
            onClick={() => {
              updateLanguageCounter("hi");
              setLanguage("hi");
              greet && play(hi);
              handleClose();
            }}
          >
            Hindi
            <br />
            (हिंदी)
          </div>
          <div
            className="language__button"
            onClick={() => {
              updateLanguageCounter("gu");
              setLanguage("gu");
              greet && play(gu);
              handleClose();
            }}
          >
            Gujarati
            <br />
            (ગુજરાતી)
          </div>
          <div
            className="language__button"
            onClick={() => {
              updateLanguageCounter("mr");
              setLanguage("mr");
              greet && play(mr);
              handleClose();
            }}
          >
            Marathi
            <br />
            (मराठी)
          </div>
          <div
            className="language__button"
            onClick={() => {
              updateLanguageCounter("bn");
              setLanguage("bn");
              greet && play(bn);
              handleClose();
            }}
          >
            Bengali
            <br />
            (বাংলা)
          </div>
          <div
            className="language__button"
            onClick={() => {
              updateLanguageCounter("te");
              setLanguage("te");
              greet && play(te);
              handleClose();
            }}
          >
            Telugu
            <br />
            (తెలుగు)
          </div>
          <div
            className="language__button"
            onClick={() => {
              updateLanguageCounter("ta");
              setLanguage("ta");
              greet && play(ta);
              handleClose();
            }}
          >
            Tamil
            <br />
            (தமிழ்)
          </div>
          <div
            className="language__button"
            onClick={() => {
              updateLanguageCounter("kn");
              setLanguage("ka");
              greet && play(kn);
              handleClose();
            }}
          >
            Kannada
            <br />
            (ಕನ್ನಡ)
          </div>
          <div
            className="language__button"
            onClick={() => {
              updateLanguageCounter("ml");
              setLanguage("ml");
              greet && play(ml);
              handleClose();
            }}
          >
            Malayalam
            <br />
            (മലയാളം)
          </div>
        </div>
        <h4>🌍 Global Languages</h4>
        <div className="language-modal__body">
          <div
            className="language__button"
            onClick={() => {
              updateLanguageCounter("en-GB");
              setLanguage("en-GB");
              greet && play(en);
              handleClose();
            }}
          >
            🇬🇧 English
          </div>
          <div
            className="language__button"
            onClick={() => {
              updateLanguageCounter("ar");
              setLanguage("ar");
              greet && play(ar);
              handleClose();
            }}
          >
            Arabic
            <br />
            (عربي)
          </div>
          <div
            className="language__button"
            onClick={() => {
              updateLanguageCounter("cmn");
              setLanguage("cmn");
              greet && play(cmn);
              handleClose();
            }}
          >
            🇨🇳 Chinese
            <br />
            (中国人)
          </div>
          <div
            className="language__button"
            onClick={() => {
              updateLanguageCounter("ja");
              setLanguage("ja");
              greet && play(ja);
              handleClose();
            }}
          >
            🇯🇵 Japanese
            <br />
            (日本語)
          </div>
          <div
            className="language__button"
            onClick={() => {
              updateLanguageCounter("ms");
              setLanguage("ms");
              greet && play(ms);
              handleClose();
            }}
          >
            🇲🇾 Malay
            <br />
            (Melayu)
          </div>
          <div
            className="language__button"
            onClick={() => {
              updateLanguageCounter("de");
              setLanguage("de");
              greet && play(de);
              handleClose();
            }}
          >
            🇩🇪 German
            <br />
            (Deutsch)
          </div>
          <div
            className="language__button"
            onClick={() => {
              updateLanguageCounter("es");
              setLanguage("es");
              greet && play(es);
              handleClose();
            }}
          >
            🇪🇸 Spanish
            <br />
            (Español)
          </div>
          <div
            className="language__button"
            onClick={() => {
              updateLanguageCounter("eu");
              setLanguage("eu");
              greet && play(eu);
              handleClose();
            }}
          >
            🇪🇸 Basque
            <br />
            (Euskara)
          </div>
          <div
            className="language__button"
            onClick={() => {
              updateLanguageCounter("fr");
              setLanguage("fr");
              greet && play(fr);
              handleClose();
            }}
          >
            🇫🇷 French
            <br />
            (Français)
          </div>
          <div
            className="language__button"
            onClick={() => {
              updateLanguageCounter("it");
              setLanguage("it");
              greet && play(it);
              handleClose();
            }}
          >
            🇮🇹 Italian
            <br />
            (Italiano)
          </div>
          <div
            className="language__button"
            onClick={() => {
              updateLanguageCounter("pt");
              setLanguage("pt");
              greet && play(pt);
              handleClose();
            }}
          >
            🇵🇹 Portuguese
            <br />
            (Português)
          </div>
          <div
            className="language__button"
            onClick={() => {
              updateLanguageCounter("sv");
              setLanguage("sv");
              greet && play(sv);
              handleClose();
            }}
          >
            🇸🇪 Swedish
            <br />
            (svenska)
          </div>
          <div
            className="language__button"
            onClick={() => {
              updateLanguageCounter("vi");
              setLanguage("vi");
              greet && play(vi);
              handleClose();
            }}
          >
            🇻🇳 Vietnamese
            <br />
            (Tiếng Việt)
          </div>
          <div
            className="language__button"
            onClick={() => {
              updateLanguageCounter("tr");
              setLanguage("tr");
              greet && play(tr);
              handleClose();
            }}
          >
            🇹🇷 Turkish
            <br />
            (Türkçe)
          </div>
          <div
            className="language__button"
            onClick={() => {
              updateLanguageCounter("el");
              setLanguage("el");
              greet && play(el);
              handleClose();
            }}
          >
            🇬🇷 Greek
            <br />
            (Ελληνικά)
          </div>
          <div
            className="language__button"
            onClick={() => {
              updateLanguageCounter("ru");
              setLanguage("ru");
              greet && play(ru);
              handleClose();
            }}
          >
            🇫🇷 Russian
            <br />
            (Русский)
          </div>
          <div
            className="language__button"
            onClick={() => {
              updateLanguageCounter("id");
              setLanguage("id");
              greet && play(id);
              handleClose();
            }}
          >
            <p>
              🇮🇩 Indonesian
              <br />
              (bahasa Indonesia)
            </p>
          </div>
        </div>
      </motion.div>
    </Backdrop>
  );
}

export default LanguageModal;

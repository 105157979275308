import axios from "axios";

const Axios = axios.create({
  // Add default config options here
  // For example, you can set baseURL, headers, etc.
  // baseURL: "http://localhost:8000/api/", // Replace with your API's base URL
  // baseURL: "https://deploy-qb3it66ftq-uc.a.run.app/api/", // Replace with your API's base URL
  // baseURL: "https://hemenparekh-backend-qb3it66ftq-el.a.run.app/api/",
  baseURL: "https://api.hemenparekh.ai/api/", // Replace with your API's base URL
});

// Add an interceptor to set the CSRF token for relative URLs
Axios.interceptors.request.use(async (config) => {
  const isRelativeUrl = !/^(http:|https:)/.test(config.url);
  if (isRelativeUrl) {
    const csrfToken = getCookie("csrftoken");
    if (csrfToken) {
      config.headers["X-CSRFToken"] = csrfToken;
    }
  }
  return config;
});

function getCookie(name) {
  const cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + "=") {
        const cookieContent = decodeURIComponent(
          cookie.substring(name.length + 1)
        );
        console.log("Found cookie:", name, cookieContent);
        return cookieContent;
      }
    }
  }
  return cookieValue;
}

export default Axios;

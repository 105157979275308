import React from "react";
import ScaleLoader from "react-spinners/ScaleLoader";

const TTSLoader = ({ audioLoading, setAudioLoading }) => {
  return (
    <div className="tts-loader">
      <ScaleLoader
        className="loader"
        color={"skyblue"}
        loading={audioLoading}
        size={10}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
};

export default TTSLoader;

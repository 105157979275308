const Nav = {
  en: [
    "DIGITAL AVATAR OF",
    "Hemen Parekh",
    "My Blogs",
    "help my avatar to answer,",
    " Questions, related to ",
    "Topics",
    "Home",
    "FAQs",
    "Recently Asked Questions",
    "Statistics",
    "My Blogs",
    "Create Your Own Digital Avatar",
  ],
  hi: [
    "डिजिटल अवतार",
    "हेमेन परेख",
    "मेरे ब्लॉग",
    "मेरे अवतार को जवाब देने में मदद करते हैं",
    " प्रश्न, ",
    " विषयों से संबंधित",
    "होम",
    "अक्सर पूछे जाने वाले प्रश्न",
    "हाल ही में पूछे गए प्रश्न",
    "आंकड़े",
    "मेरे ब्लॉग",
    "अपना डिजिटल अवतार बनाएं",
  ],
  gu: [
    "ડિજિટલ એવતાર",
    "હેમેન પરેખ",
    "મારા બ્લોગ",
    "મારા એવતારને જવાબ આપવામાં મદદ કરે છે",
    " પ્રશ્ન, ",
    " વિષયોથી સંબંધિત",
    "હોમ",
    "વારંવાર પૂછાતા પ્રશ્નો",
    "હાલમાં પૂછવામાં આવતા પ્રશ્નો",
    "આંકડા",
    "મારા બ્લોગ",
    "તમારો ડિજિટલ એવતાર બનાવો",
  ],
  mr: [
    "डिजिटल अवतार",
    "हेमेन परेख",
    "माझे ब्लॉग्ज",
    "माझ्या अवतारला उत्तर देण्यात मदत करतात",
    " प्रश्न, ",
    " विषयांसे संबंधित",
    "होम",
    "सतत विचारले जाणारे प्रश्न",
    "आता किंवा होते प्रश्न",
    "आंकडे",
    "माझे ब्लॉग्ज",
    "तुमचा स्वतंत्र डिजिटल अवतार तयार करा",
  ],
  bn: [
    "ডিজিটাল অবতার",
    "হেমেন পারেখ",
    "আমার ব্লগ",
    "আমার অবতারকে উত্তর দিতে আমার ব্লগগুলি সাহায্য করে",
    " প্রশ্ন, ",
    " বিষয়ে সংবাদপ্রদ",
    "হোম",
    "সচরাচর জিজ্ঞাস্য",
    "সাম্প্রতিক প্রশ্নগুলি",
    "পরিসংখ্যান",
    "আমার ব্লগ",
    "আপনার নিজস্ব ডিজিটাল অবতার তৈরি করুন",
  ],
  te: [
    "డిజిటల్ అవతార్",
    "హేమేన్ పరేఖ్",
    "నా బ్లాగులు",
    "నా అవతార్ ప్రశ్నకు సహాయపడిపెడతాయి",
    " ప్రశ్నలు, ",
    " విషయాల సంబంధించి",
    "హోమ్",
    "తరచుగా అడుగు ప్రశ్నలు",
    "ఇటీవల అడిగిన ప్రశ్నలు",
    "గణాంకాలు",
    "నా బ్లాగులు",
    "మీ స్వంత డిజిటల్ అవతార్ సృష్టించండి",
  ],
  ta: [
    "கணினி அவதாரம்",
    "ஹேமேன் பரேக்",
    "என் பலங்கள்",
    "என் அவதாருக்கு கேட்கும் விளக்கம் செய்து உதவுகின்றன",
    " கருத்துக்கள், ",
    " தலைப்புக்கு உடைந்த",
    "முகப்பு",
    "அடிக்கடி கேட்கப்படும் கேள்விகள்",
    "சமீபத்தில் கேட்கப்பட்ட கேள்விகள்",
    "புள்ளிவிபரங்கள்",
    "என் பலங்கள்",
    "உங்கள் கணினி அவதாரை உருவாக்கவும்",
  ],
  ka: [
    "ಡಿಜಿಟಲ್ ಅವತಾರ",
    "ಹೇಮೇನ್ ಪರೇಖ್",
    "ನನ್ನ ಬ್ಲಾಗ್‌ಗಳು",
    "ನನ್ನ ಅವತಾರಕ್ಕೆ ಪ್ರಶ್ನೆಗಳಿಗೆ ಸಹಾಯ ಮಾಡುತ್ತವೆ",
    " ವಿಷಯಗಳ ಸಂಬಂಧಿಸಿದ ",
    "ಪ್ರಶ್ನೆಗಳು",
    "ಹೋಮ್",
    "ಪದೇ ಪದೇ ಕೇಳಲಾಗುವ ಪ್ರಶ್ನೆಗಳು",
    "ಇತ್ತೀಚಿನ ಪ್ರಶ್ನೆಗಳು",
    "ಸಾಂಖ್ಯಿಕಗಳು",
    "ನನ್ನ ಬ್ಲಾಗ್‌ಗಳು",
    "ನಿಮ್ಮ ಸ್ವಂತ ಡಿಜಿಟಲ್ ಅವತಾರವನ್ನು ರಚಿಸಿ",
  ],
  ml: [
    "ഡിജിറ്റൽ അവതാർ",
    "ഹേമൻ പരേഖ്",
    "എന്റെ ബ്ലോഗുകൾ",
    "എന്റെ അവതാർക്ക് ഉത്തരം നൽകാൻ സഹായിക്കുന്നു",
    " ചോദ്യങ്ങൾ, ",
    " വിഷയങ്ങളോടുകൂടി ബന്ധപ്പെട്ട്",
    "ഹോം",
    "പതിവായി ചോദിക്കുന്ന ചോദ്യങ്ങൾ",
    "ഇതുവരെ ചോദിച്ച ചോദ്യങ്ങൾ",
    "സ്റ്റാറ്റിസ്റ്റിക്സ്",
    "എന്റെ ബ്ലോഗുകൾ",
    "നിനക്ക് സ്വന്തമായ ഡിജിറ്റൽ അവതാർ സൃഷ്ടിക്കാൻ",
  ],
  "en-GB": [
    "DIGITAL AVATAR OF",
    "Hemen Parekh",
    "My Blogs",
    "help my avatar to answer,",
    " Questions, related to ",
    "Topics",
    "Home",
    "FAQs",
    "Recently Asked Questions",
    "Statistics",
    "My Blogs",
    "Create Your Own Digital Avatar",
  ],
  ar: [
    "الصورة الرقمية لـ",
    "هيمن باريخ",
    "مدوناتي",
    "تساعد مدوناتي في الإجابة على أسئلتي،",
    " الخاصة بـ",
    "المواضيع",
    "الرئيسية",
    "أسئلة مكررة",
    "الأسئلة المطروحة مؤخرًا",
    "الإحصائيات",
    "مدوناتي",
    "أنشئ صورتك الرقمية الخاصة",
  ],
  cmn: [
    "数字化身",
    "赫门·帕雷克",
    "我的博客",
    "帮助我的头像做出回应",
    " 问题， ",
    "相关话题",
    "家",
    "经常问的问题",
    "最近提出的问题",
    "数据",
    "我的博客",
    "创建你的数字化身",
  ],
  ja: [
    "デジタルアバター",
    "ヘメン・パレック",
    "私のブログ",
    "私のアバターが答えるのを助ける",
    " 質問、 ",
    "トピックに関連する",
    "ホーム",
    "よくある質問",
    "最近の質問",
    "統計",
    "私のブログ",
    "あなた自身のデジタルアバターを作成する",
  ],
  ms: [
    "AVATAR DIGITAL",
    "Hemen Parekh",
    "Blog Saya",
    "bantu avatar saya untuk menjawab,",
    " Pertanyaan, terkait ",
    "Topik",
    "Rumah",
    "Soalan Lazim",
    "Soalan yang Ditanya Baru-baru ini",
    "Statistik",
    "Blog Saya",
    "Cipta Avatar Digital Anda Sendiri",
  ],
  de: [
    "DIGITAL AVATAR OF",
    "Hemen Parekh",
    "Meine Blogs",
    "hilf meinem Avatar zu antworten,",
    " Fragen, die sich auf ",
    "Themen",
    "Zuhause",
    "FAQs",
    "Kürzlich gestellte Fragen",
    "Statistiken",
    "Meine Blogs",
    "Erstellen Sie Ihren eigenen digitalen Avatar",
  ],
  es: [
    "AVATAR DIGITAL DE",
    "Hemen Parekh",
    "Mis blogs",
    "ayuda a mi avatar a responder,",
    " Preguntas, relacionadas con ",
    "Temas",
    "Casa",
    "Preguntas frecuentes",
    "Preguntas recientemente hechas",
    "Estadísticas",
    "Mis blogs",
    "Crea tu propio avatar digital",
  ],
  eu: [
    "DIGITAL AVATAR OF",
    "Hemen Parekh",
    "Nire blogak",
    "nire avatarra erantzuteko laguntzen du,",
    " Galderak, honi buruz ",
    "Gaiak",
    "Etxea",
    "FAQs",
    "Azkenaldian egin diren galderak",
    "Estatistikak",
    "Nire blogak",
    "Sortu zure avatar digitala",
  ],
  fr: [
    "AVATAR NUMÉRIQUE DE",
    "Hemen Parekh",
    "Mes blogs",
    "aidez mon avatar à répondre,",
    " Questions, liées à ",
    "Sujets",
    "Accueil",
    "FAQ",
    "Questions récemment posées",
    "Statistiques",
    "Mes blogs",
    "Créez votre propre avatar numérique",
  ],
  it: [
    "AVATAR DIGITALE DI",
    "Hemen Parekh",
    "I miei blog",
    "aiuta il mio avatar a rispondere,",
    " Domande, relative a ",
    "Argomenti",
    "Casa",
    "FAQ",
    "Domande recentemente poste",
    "Statistiche",
    "I miei blog",
    "Crea il tuo avatar digitale",
  ],
  pt: [
    "AVATAR DIGITAL DE",
    "Hemen Parekh",
    "Meus blogs",
    "ajude meu avatar a responder,",
    " Perguntas, relacionadas a ",
    "Tópicos",
    "Casa",
    "FAQs",
    "Perguntas feitas recentemente",
    "Estatísticas",
    "Meus blogs",
    "Crie seu próprio avatar digital",
  ],
  sv: [
    "DIGITAL AVATAR OF",
    "Hemen Parekh",
    "Mina bloggar",
    "hjälp min avatar att svara,",
    " Frågor, relaterade till ",
    "Ämnen",
    "Hem",
    "FAQs",
    "Nyligen ställda frågor",
    "Statistik",
    "Mina bloggar",
    "Skapa din egen digitala avatar",
  ],
  vi: [
    "AVATAR SỐ HÓA CỦA",
    "Hemen Parekh",
    "Những blog của tôi",
    "giúp avatar của tôi trả lời,",
    " Câu hỏi, liên quan đến ",
    "Chủ đề",
    "Nhà",
    "FAQs",
    "Câu hỏi được đặt gần đây",
    "Thống kê",
    "Những blog của tôi",
    "Tạo hình ảnh đại diện kỹ thuật số của riêng bạn",
  ],
  tr: [
    "DIGITAL AVATAR OF",
    "Hemen Parekh",
    "Bloglarım",
    "avatarıma cevap vermesine yardım et,",
    " Sorular, ile ilgili ",
    "Konular",
    "Ev",
    "SSS",
    "Son Sorulan Sorular",
    "İstatistikler",
    "Bloglarım",
    "Kendi dijital avatarınızı oluşturun",
  ],
  el: [
    "ΨΗΦΙΑΚΟ ΑΒΑΤΑΡ ΤΟΥ",
    "Hemen Parekh",
    "Τα ιστολόγιά μου",
    "βοηθήστε το αβατάρ μου να απαντήσει,",
    " Ερωτήσεις, σχετικά με ",
    "Θέματα",
    "Σπίτι",
    "Συχνές ερωτήσεις",
    "Πρόσφατες ερωτήσεις",
    "Στατιστικά",
    "Τα ιστολόγιά μου",
    "Δημιουργήστε το δικό σας ψηφιακό αβατάρ",
  ],
  ru: [
    "ЦИФРОВОЙ АВАТАР",
    "Хемен Парех",
    "Мои блоги",
    "помогите моему аватару ответить,",
    " Вопросы, связанные с ",
    "Темы",
    "Главная",
    "Часто задаваемые вопросы",
    "Недавно заданные вопросы",
    "Статистика",
    "Мои блоги",
    "Создайте свой собственный цифровой аватар",
  ],
  id: [
    "AVATAR DIGITAL",
    "Hemen Parekh",
    "Blog Saya",
    "bantu avatarku untuk menjawab",
    "Pertanyaan, berkaitan dengan",
    "Topik",
    "Rumah",
    "FAQ",
    "Pertanyaan Baru-baru ini Diajukan",
    "Statistik",
    "Blog Saya",
    "Buat Avatar Digital Anda Sendiri",
  ],
};

export default Nav;

import React from "react";
// import Sketch from "../assets/hcp.png";
import "../App.css";
import Axios from "../Axios";
import { useState, useEffect } from "react";
import Modal from "./Modal/index";
import { AnimatePresence } from "framer-motion";
import AnimatedPage from "./AnimatedPage";
import SyncLoader from "react-spinners/GridLoader";
import LanguageModal from "./LanguageModal/index";
import { IoLanguageSharp } from "react-icons/io5";
import { FaMicrophone } from "react-icons/fa";
import { FaCircleStop } from "react-icons/fa6";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import translate from "translate";

// import Rating from "@mui/material/Rating";
import {
  FaEnvelope,
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaWhatsapp,
} from "react-icons/fa";
import Hcp from "./Hcp";
import TextToSpeech from "./TextToSpeech";

function Home({
  topics,
  topicQuestionsMap,
  length,
  questionField,
  setQuestion,
  submitButton,
  setSubmitButton,
  clearButton,
  setClearButton,
  answerField,
  setAnswer,
  visitors,
  socketId,
  value,
  setValue,
  topicLoading,
  loader,
  shareButton,
  setShareButton,
  audioFile,
  setAudioFile,
  player,
  setPlayer,
  audioLoading,
  setAudioLoading,
  language,
  setLanguage,
  selectRegion,
  setSelectedRegion,
  greet,
  setGreet,
  languageModalOpen,
  setLanguageModalOpen,
  homeContent,
  socket,
}) {
  // const [rate, setRate] = useState("home__rate--disabled");
  const [modalOpen, setModalOpen] = useState(false);
  const [topic, setTopic] = useState("");
  const [loading, setLoading] = useState(false);
  // const [rateVal, setRateVal] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  const [answerRows, setAnswerRows] = useState(6);
  const [micButton, setMicButton] = useState("mic__button");
  const { transcript, listening, resetTranscript } = useSpeechRecognition();
  // const [text, setText] = useState("");

  const shareUrl = "https://hemenparekh.ai"; // Replace with your website URL
  const subject = "Check out this website";
  const body = "I thought you might find this interesting: ";

  const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&to=&su=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body + shareUrl)}`;

  const text = "Check out this awesome website!";

  const twitterShareLink = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
    shareUrl
  )}&text=${encodeURIComponent(text)}`;

  translate.engine = "google";

  const languages = {
    en: "🇮🇳 English",
    hi: "🇮🇳 Hindi",
    gu: "🇮🇳 Gujarati",
    mr: "🇮🇳 Marathi",
    bn: "🇮🇳 Bengali",
    te: "🇮🇳 Telugu",
    ta: "🇮🇳 Tamil",
    ka: "🇮🇳 Kannada",
    ml: "🇮🇳 Malayalam",
    ar: "Arabic",
    cmn: "🇨🇳 Chinese",
    ja: "🇯🇵 Japanese",
    ms: "🇲🇾 Malay",
    de: "🇩🇪 German",
    "en-GB": "🇬🇧 English",
    es: "🇪🇸 Spanish",
    eu: "🇪🇸 Basque",
    fr: "🇫🇷 French",
    it: "🇮🇹 Italian",
    pt: "🇵🇹 Portuguese",
    sv: "🇸🇪 Swedish",
    vi: "🇻🇳 Vietnamese",
    tr: "🇹🇷 Turkish",
    el: "🇬🇷 Greek",
    ru: "🇷🇺 Russian",
    id: "🇮🇩 Indonesian",
  };

  const handleRegionChange = (region) => {
    if (region === "india") {
      setLanguage("en");
    } else {
      setLanguage("en-GB");
    }
    setSelectedRegion(region);
  };

  // function submitRating(val) {
  //   const url = "/post_rating/";
  //   const payload = {
  //     socketId: socketId,
  //     question: questionField,
  //     rating: val,
  //   };
  //   const headers = {
  //     "Content-Type": "application/json",
  //   };
  //   Axios.post(url, payload, headers);
  // }

  function shareOnWhatsApp() {
    let url;
    if (windowWidth > 805) {
      url = `https://web.whatsapp.com/send?text=Question:%0A${encodeURIComponent(
        questionField
      )}%0A%0AAnswer:%0A${encodeURIComponent(
        answerField
      )}%0A%0ASource:%0Ahttps://www.hemenparekh.ai`;
    } else {
      url = `whatsapp://send?text=Question:%0A${encodeURIComponent(
        questionField
      )}%0A%0AAnswer:%0A${encodeURIComponent(
        answerField
      )}%0A%0ASource:%0Ahttps://www.hemenparekh.ai`;
    }
    window.open(url);
  }

  const close = (event) => {
    setModalOpen(false);
    if (event.target.innerText !== "X") {
      setQuestion(event.target.innerText);
      setAnswer("");
      setAudioFile(null);
      setPlayer("player--disabled");
      setClearButton("button__wrapper");
      setSubmitButton("button__wrapper");
    }
  };

  const open = () => {
    setModalOpen(true);
  };

  // useEffect(() => {
  //   if (questionField === "" || answerField === "") {
  //     setRate("home__rate--disabled");
  //   }
  // }, [questionField, answerField]);

  useEffect(() => {
    if (windowHeight <= 667) {
      setAnswerRows(6);
    } else {
      setAnswerRows(6);
    }
  }, [windowHeight]);

  useEffect(() => {
    if (transcript !== "") {
      setQuestion(transcript);
    }
  }, [transcript, setQuestion]);

  useEffect(() => {
    if (questionField !== "") {
      setClearButton("button__wrapper");
      setSubmitButton("button__wrapper");
    }
  }, [questionField, setClearButton, setSubmitButton]);

  useEffect(() => {
    console.log(questionField);
  }, [questionField]);

  const handleGenerateAudio = async (text, languageParam) => {
    try {
      setAudioLoading(true);
      setPlayer("player--disabled");
      console.log(`Generating audio... from \n${text}`);
      const response = await Axios.post(
        "/text_to_speech/",
        { text: text, language: languageParam },
        { responseType: "blob" }
      );
      const blob = new Blob([response.data], { type: "audio/mp3" });
      const url = URL.createObjectURL(blob);
      setAudioFile(url);
      setPlayer("player--enabled");
      setAudioLoading(false);
    } catch (error) {
      console.error(error);
      setAudioLoading(false);
    }
  };

  async function getAnswer() {
    socket.emit("new_question_posted", socket.id);
    setLoading(true);
    setSubmitButton("button__wrapper--disabled");
    setShareButton("button__wrapper--disabled");
    setAnswer("");
    const url = "/answers/";
    let question = "";
    if (language !== "en" || language !== "en-GB") {
      question = await translate(questionField, {
        from: language,
        to: "en",
      });
    }
    const payload = {
      Text:
        language === "en" || language === "en-GB" ? questionField : question,
      socketId: socketId,
    };
    const headers = {
      "Content-Type": "application/json",
    };

    Axios.post(url, payload, { headers })
      .then(async (response) => {
        let answer = await translate(response.data.ai_message, {
          from: "en",
          to: language,
        });
        setAnswer(answer);
        setSubmitButton("button__wrapper");
        setClearButton("button__wrapper");
        setShareButton("button__wrapper");
        setLoading(false);
        handleGenerateAudio(response.data.ai_message, language);
        // setRate("home__rate--enabled");
      })
      .catch((error) => {
        console.error(error);
        setSubmitButton("button__wrapper");
        setClearButton("button__wrapper");
        setAnswer(
          "Oops! An error Occured, Try resubmitting the question. If the problem persists, please contact the developer."
        );
        setLoading(false);
      });
  }

  return (
    <div>
      <Hcp
        topics={topics}
        value={value}
        length={length}
        setValue={setValue}
        open={open}
        close={close}
        topic={topic}
        setTopic={setTopic}
        modalOpen={modalOpen}
        topicLoading={topicLoading}
        loader={loader}
      />
      <div className="rate-count-social__wrapper--mobile">
        {/* <div className="rate-count-social__inner-container">
                    <div className={rate}>
                      <p>Rate my answer</p>
                      <Rating
                        name="simple-controlled"
                        value={rateVal}
                        onChange={(event, val) => {
                          submitRating(val);
                          setRateVal(val);
                        }}
                      />
                    </div>
                  </div> */}
        <div className="home__visitors-count">
          <span>
            {homeContent[9]}
            {visitors}
          </span>
        </div>
        <div className="social-icons__container">
          <a
            href="https://www.facebook.com/sharer/sharer.php?u=https://hemenparekh.vercel.app"
            target="_blank"
            rel="noopener noreferrer"
            className="icon"
          >
            <FaFacebook />
          </a>
          <a
            href={twitterShareLink}
            target="_blank"
            rel="noopener noreferrer"
            className="icon"
          >
            <FaTwitter />
          </a>
          <a
            href={`https://www.linkedin.com/sharing/share-offsite/?url=https://hemenparekh.ai`}
            target="_blank"
            rel="noopener noreferrer"
            className="icon"
          >
            <FaLinkedin />
          </a>
          <a
            href={mailtoLink}
            target="_blank"
            rel="noopener noreferrer"
            className="icon"
          >
            <FaEnvelope />
          </a>
        </div>
        <div className="contact-developer">
          <span>Launched on February 10, 2023</span>
          <span id="developed-by">
            The site is developed and maintained by
            <a
              className="footer__link"
              href="https://www.linkedin.com/in/KishanKokal/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Kishan Kokal
            </a>
          </span>
        </div>
      </div>
      <AnimatedPage>
        <div className="home__container">
          <div className="home__wrapper">
            <div className="home__question-answer--wrapper">
              <div className="question__wrapper">
                <div className="question__container">
                  <span>{homeContent[0]}</span>
                  <textarea
                    value={questionField}
                    onChange={(e) => {
                      if (e.target.value === "") {
                        setClearButton("button__wrapper--disabled");
                        setSubmitButton("button__wrapper--disabled");
                      } else {
                        setClearButton("button__wrapper");
                        setSubmitButton("button__wrapper");
                      }
                      setQuestion(e.target.value);
                      setAnswer("");
                      // setRateVal(null);
                      // setRate("home__rate--disabled");
                      setShareButton("button__wrapper--disabled");
                      setAudioFile(null);
                      setPlayer("player--disabled");
                    }}
                    rows="3"
                    placeholder={homeContent[1]}
                  ></textarea>
                  <div className="question-answer-buttons__wrapper">
                    <div
                      className={submitButton}
                      onClick={() => {
                        setMicButton("mic__button");
                        SpeechRecognition.stopListening();
                        getAnswer();
                        setSubmitButton("button__wrapper--disabled");
                        setClearButton("button__wrapper--disabled");
                        setAudioFile(null);
                        setPlayer("player--disabled");
                      }}
                    >
                      {homeContent[2]}
                    </div>
                    <div
                      className={clearButton}
                      onClick={() => {
                        SpeechRecognition.stopListening();
                        resetTranscript();
                        setMicButton("mic__button");
                        setQuestion("");
                        setAnswer("");
                        // setRateVal(null);
                        setClearButton("button__wrapper--disabled");
                        setSubmitButton("button__wrapper--disabled");
                        setShareButton("button__wrapper--disabled");
                        setAudioFile(null);
                        setPlayer("player--disabled");
                      }}
                    >
                      {homeContent[3]}
                    </div>
                  </div>
                  <div className="share__wrapper">
                    <button
                      className={shareButton}
                      onClick={() => shareOnWhatsApp()}
                    >
                      <span>
                        {homeContent[4]}
                        <FaWhatsapp className="whatsapp-icon" />
                      </span>
                    </button>
                  </div>
                </div>
                <div className="mic__wrapper">
                  <div
                    className={micButton}
                    onClick={() => {
                      if (micButton === "mic__button") {
                        setMicButton("pause__button");
                        resetTranscript();
                        setClearButton("button__wrapper--disabled");
                        setSubmitButton("button__wrapper--disabled");
                        SpeechRecognition.startListening({
                          continuous: true,
                          language: language,
                        });
                      } else {
                        setMicButton("mic__button");
                        SpeechRecognition.stopListening();
                      }
                    }}
                  >
                    {micButton === "mic__button" ? (
                      <FaMicrophone className="mic-icon" />
                    ) : (
                      <FaCircleStop className="mic-icon" />
                    )}
                  </div>
                </div>
              </div>
              <div className="answer__wrapper">
                <div className="answer__container">
                  <div className="language__select--container">
                    {/* <p>Select Language:</p>
                    <div className="language__region-selection">
                      <label>
                        <input
                          type="radio"
                          value="male"
                          checked={selectRegion === "india"}
                          onChange={() => handleRegionChange("india")}
                        />{" "}
                        Indian
                      </label>
                      <label>
                        <input
                          type="radio"
                          value="female"
                          checked={selectRegion === "global"}
                          onChange={() => handleRegionChange("global")}
                        />{" "}
                        Global
                      </label>
                      
                    </div>
                    {selectRegion === "india" ? (
                      <select
                        className="language__select"
                        value={language}
                        onChange={(e) => {
                          setLanguage(e.target.value);
                          if (answerField !== "") {
                            handleGenerateAudio(answerField, e.target.value);
                          }
                        }}
                      >
                        <option value="en">English (India)</option>
                        <option value="hi">Hindi</option>
                        <option value="gu">Gujarati</option>
                        <option value="mr">Marathi</option>
                        <option value="bn">Bengali</option>
                        <option value="te">Telugu</option>
                        <option value="ta">Tamil</option>
                        <option value="ka">Kannada</option>
                        <option value="ml">Malayalam</option>
                      </select>
                    ) : (
                      <select
                        className="language__select"
                        value={language}
                        onChange={(e) => {
                          setLanguage(e.target.value);
                          if (answerField !== "") {
                            handleGenerateAudio(answerField, e.target.value);
                          }
                        }}
                      >
                        <option value="en-GB">English (UK)</option>
                        <option value="ar">Arabic</option>
                        <option value="cmn">Chinese (Mandarin)</option>
                        <option value="ja">Japanese (Japan)</option>
                        <option value="ms">Malay (Malaysia)</option>
                        <option value="de">German (Germany)</option>
                        <option value="es">Spanish (Spain)</option>
                        <option value="eu">Basque (Spain)</option>
                        <option value="fr">French (France)</option>
                        <option value="it">Italian (Italy)</option>
                        <option value="pt">Portuguese (Portugal)</option>
                        <option value="sv">Swedish (Sweden)</option>
                        <option value="vi">Vietnamese (Vietnam)</option>
                        <option value="tr">Turkish (Turkey)</option>
                        <option value="el">Greek (Greece)</option>
                        <option value="ru">Russian (Russia)</option>
                        <option value="id">Indonesian (Indonesia)</option>
                      </select>
                    )} */}
                    <div
                      className="language__select--button"
                      onClick={() => {
                        setLanguageModalOpen(true);
                      }}
                    >
                      {homeContent[5]} {<IoLanguageSharp />}
                    </div>
                    <p>
                      {homeContent[6]}
                      {languages[language]}
                    </p>
                    <TextToSpeech
                      audioFile={audioFile}
                      player={player}
                      audioLoading={audioLoading}
                      setAudioLoading={setAudioLoading}
                    />
                  </div>
                  <div className="answer__header">
                    <div className="loader__container">
                      {loading ? (
                        <SyncLoader
                          className="loader"
                          color={"skyblue"}
                          loading={loading}
                          size={7}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      ) : null}
                    </div>
                  </div>
                  <textarea
                    className="answer__textarea"
                    value={answerField}
                    id=""
                    rows={answerRows}
                    placeholder={homeContent[7]}
                    readOnly
                  ></textarea>
                  <div className="rate-count-social__wrapper">
                    {/* <div className="rate-count-social__inner-container">
                    <div className={rate}>
                      <p>Rate my answer</p>
                      <Rating
                        name="simple-controlled"
                        value={rateVal}
                        onChange={(event, val) => {
                          submitRating(val);
                          setRateVal(val);
                        }}
                      />
                    </div>
                  </div> */}

                    <div className="home__visitors-count">
                      <span>
                        {homeContent[8]}
                        {visitors}
                      </span>
                    </div>
                    <div className="social-icons__container">
                      <a
                        href="https://www.facebook.com/sharer/sharer.php?u=https://hemenparekh.vercel.app"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="icon"
                      >
                        <FaFacebook />
                      </a>
                      <a
                        href={twitterShareLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="icon"
                      >
                        <FaTwitter />
                      </a>
                      <a
                        href={`https://www.linkedin.com/sharing/share-offsite/?url=https://hemenparekh.ai`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="icon"
                      >
                        <FaLinkedin />
                      </a>
                      <a
                        href={mailtoLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="icon"
                      >
                        <FaEnvelope />
                      </a>
                    </div>
                    <div className="contact-developer">
                      <span>Launched on February 10, 2023</span>
                      <span id="developed-by">
                        The site is developed and maintained by
                        <a
                          className="footer__link"
                          href="https://www.linkedin.com/in/KishanKokal/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {" "}
                          Kishan Kokal
                        </a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AnimatePresence
            initial={false}
            mode="wait"
            onExitComplete={() => null}
          >
            {modalOpen && (
              <Modal
                modalOpen={modalOpen}
                handleClose={close}
                topic={topic}
                questions={topicQuestionsMap[topic]}
                setQuestion={setQuestion}
              />
            )}
          </AnimatePresence>
          <AnimatePresence
            initial={false}
            mode="wait"
            onExitComplete={() => null}
          >
            {languageModalOpen && (
              <LanguageModal
                modalOpen={languageModalOpen}
                greet={greet}
                handleClose={() => {
                  setLanguageModalOpen(false);
                  setGreet(false);
                }}
                setLanguage={setLanguage}
              />
            )}
          </AnimatePresence>
        </div>
      </AnimatedPage>
    </div>
  );
}

export default Home;

// TextToSpeechComponent.js

import ReactAudioPlayer from "react-audio-player";
import "../App.css";
import TTSLoader from "./TTSLoader";

const TextToSpeechComponent = ({
  audioFile,
  player,
  audioLoading,
  setAudioLoading,
}) => {
  return (
    <div className="tts">
      <ReactAudioPlayer src={audioFile} controls className={player} />
      <TTSLoader
        audioLoading={audioLoading}
        setAudioLoading={setAudioLoading}
      />
    </div>
  );
};

export default TextToSpeechComponent;

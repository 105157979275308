import "./App.css";
import Faqs from "./components/Faqs";
import Nav from "./components/Nav";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Recentqs from "./components/Recentqs";
import Stats from "./components/Stats";
import Home from "./components/Home";
import Axios from "./Axios";
import { useState, useEffect } from "react";
import io from "socket.io-client";
import { AnimatePresence } from "framer-motion";
import NavModal from "./components/NavModal/index";
import TranslatedNav from "./translations/TranslatedNav";
import TranslatedHome from "./translations/TranslatedHome";
import TranslatedFaqs from "./translations/TranslatedFaqs";
import TranslatedRecentlyAsked from "./translations/TranslatedRecentlyAsked";
import TranslatedStats from "./translations/TranslatedStats";
import CreateYourAvatar from "./components/CreateYourAvatar";
import translate from "translate";

function App() {
  const [socketId, setSocketId] = useState();
  const [totalQuestions, setTotalQuestions] = useState("Loading...");
  const [visitors, setVisitors] = useState(0);
  const [questionsRated, setQuestionsRated] = useState("Loading...");
  const [avgRating, setAvgRating] = useState("Loading...");
  const [avgQuestionsPerVisitor, setAvgQuestionsPerVisitor] =
    useState("Loading...");
  const [totalVisitors, setTotalVisitors] = useState("Loading...");
  const [topics, setTopics] = useState([]);
  const [topicQuestionsMap, setTopicQuestionsMap] = useState({});
  const [totalQuestionsInDB, setTotalQuestionsInDB] = useState("Loading...");
  const [totalTopicsInDB, setTotalTopicsInDB] = useState("Loading...");
  const [questionField, setQuestion] = useState("");
  const [submitButton, setSubmitButton] = useState("button__wrapper--disabled");
  const [clearButton, setClearButton] = useState("button__wrapper--disabled");
  const [answerField, setAnswer] = useState("");
  const [value, setValue] = useState(0);
  const [topicLoading, setTopicLoading] = useState(false);
  const [loader, setLoader] = useState("hcp__loader--disabled");
  const [navModalOpen, setNavModalOpen] = useState(false);
  const [shareButton, setShareButton] = useState("button__wrapper--disabled");
  const [audioFile, setAudioFile] = useState(null);
  const [player, setPlayer] = useState("player--disabled");
  const [audioLoading, setAudioLoading] = useState(false);
  const [visitorsYesterday, setVisitorsYesterday] = useState("Loading...");
  const [visitorsToday, setVisitorsToday] = useState("Loading...");
  const [languageCounters, setLanguageCounters] = useState([]);
  const [language, setLanguage] = useState("en");
  const [selectRegion, setSelectedRegion] = useState("india");
  const [languageModalOpen, setLanguageModalOpen] = useState(false);
  const [greet, setGreet] = useState(true);
  const [topicFlag, setTopicFlag] = useState(false);
  const [socket, setSocket] = useState(null);
  const [recentQuestionsAlert, setRecentQuestionsAlert] =
    useState("nav__link--text");

  translate.engine = "google";

  const close = (event) => {
    setNavModalOpen(false);
  };

  const open = () => {
    setNavModalOpen(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setLanguageModalOpen(true);
    }, 500);
  }, []);

  async function translateArray(array, toLanguage) {
    if (toLanguage === "cmn") {
      toLanguage = "zh";
    }
    const translationPromises = array.map(async (questions) => {
      const translatedQuestions = await Promise.all(
        questions.map(async (question) => {
          try {
            // Use your translation API or function here
            const translatedQuestion = await translate(question, {
              from: "en",
              to: toLanguage,
            });
            return translatedQuestion;
          } catch (error) {
            console.error(error);
            // Handle translation error, return original question, or other strategy
            return question;
          }
        })
      );
      return translatedQuestions;
    });

    return Promise.all(translationPromises);
  }

  const getNewTopicsAndQuestions = async () => {
    const url = "/get_topics_and_questions/";
    const result = await Axios.get(url);
    const questions = Object.values(result.data.topic_questions_map);
    let translatedTopics = [];
    let translatedQuestions = [];
    while (translatedTopics.length === 0) {
      try {
        const text = result.data.topics;
        const translationPromises = text.map(async (topic) => {
          let translatedTopic;
          if (language === "cmn") {
            translatedTopic = await translate(topic, {
              from: "en",
              to: "zh",
            });
          } else if (language === "en-GB") {
            translatedTopic = await translate(topic, {
              from: "en",
              to: "en",
            });
          } else {
            translatedTopic = await translate(topic, {
              from: "en",
              to: language,
            });
          }
          return translatedTopic;
        });
        translatedTopics = await Promise.all(translationPromises);
      } catch {}
    }
    while (translatedQuestions.length === 0) {
      try {
        translatedQuestions = await translateArray(questions, language);
      } catch {}
    }
    let translatedTopicQuestionsMap = {};
    for (let i = 0; i < translatedTopics.length; i++) {
      translatedTopicQuestionsMap[translatedTopics[i]] = translatedQuestions[i];
    }
    setTopics([...topics, ...translatedTopics]);
    setTopicQuestionsMap({
      ...topicQuestionsMap,
      ...translatedTopicQuestionsMap,
    });
    setTopicFlag(false);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setValue((prevVal) => prevVal + 1);
    }, 5000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    if (topics.length - value === 10 && !topicFlag) {
      getNewTopicsAndQuestions();
      setTopicFlag(true);
    } else if (topics.length - value <= 0) {
      setValue(0);
    }
  }, [value]);

  useEffect(() => {
    // const newSocket = io("https://hemenparekh-socket-qb3it66ftq-el.a.run.app");
    const newSocket = io("https://api.hemenparekh.ai/");
    // const newSocket = io("http://localhost:8081");

    newSocket.on("connect", () => {
      console.log("Connected to server");
    });
    newSocket.on("socketId", (id) => {
      setSocketId(id);
    });
    newSocket.on("message", (text) => {
      setVisitors(text);
    });
    newSocket.on("new_question_posted", (id) => {
      setRecentQuestionsAlert("nav__link--text--alert");
    });
    function handleOnBeforeUnload(event) {
      newSocket.disconnect();
    }
    window.addEventListener("beforeunload", handleOnBeforeUnload, {
      capture: true,
    });

    const getCounters = async () => {
      const url = "/counters/";
      const result = await Axios.get(url);
      setTotalQuestions((totalQuestions) => result.data.total_questions_asked);
      setTotalVisitors((totalVisitors) => result.data.total_users);
      setAvgQuestionsPerVisitor(
        (avgQuestionsPerVisitor) => result.data.avg_questions_per_user
      );
      setQuestionsRated((questionsRated) => result.data.questions_rated);
      setAvgRating((avgRating) => result.data.avg_rating.toFixed(1));
      setTotalQuestionsInDB(
        (totalQuestionsInDB) => result.data.total_questions
      );
      setTotalTopicsInDB((totalTopicsInDB) => result.data.total_topics);
      setVisitorsYesterday(
        (visitorsYesterday) => result.data.visitors_yesterday
      );
      setVisitorsToday((visitorsToday) => result.data.visitors_today);
      setLanguageCounters((languageCounters) => result.data.language_counters);
    };

    // const getTopicQuestions = async () => {
    //   setTopicLoading(true);
    //   setLoader("hcp__loader");
    //   const url = "/get_topics_and_questions/";
    //   const result = await Axios.get(url);
    //   setTopics((topics) => result.data.topics);
    //   setTopicQuestionsMap(
    //     (topicQuestionsMap) => result.data.topic_questions_map
    //   );
    //   setTopicLoading(false);
    //   setLoader("hcp__loader--disabled");
    // };

    // getTopicQuestions();
    setSocket(newSocket);
    getCounters();
  }, []);

  useEffect(() => {
    const getTopicQuestions = async () => {
      setTopicLoading(true);
      setLoader("hcp__loader");
      const url = "/get_topics_and_questions/";
      const result = await Axios.get(url);
      const questions = Object.values(result.data.topic_questions_map);
      let translatedTopics = [];
      let translatedQuestions = [];
      while (translatedTopics.length === 0) {
        try {
          const text = result.data.topics;
          const translationPromises = text.map(async (topic) => {
            let translatedTopic;
            if (language === "cmn") {
              translatedTopic = await translate(topic, {
                from: "en",
                to: "zh",
              });
            } else if (language === "en-GB") {
              translatedTopic = await translate(topic, {
                from: "en",
                to: "en",
              });
            } else {
              translatedTopic = await translate(topic, {
                from: "en",
                to: language,
              });
            }
            return translatedTopic;
          });
          translatedTopics = await Promise.all(translationPromises);
        } catch {}
      }
      while (translatedQuestions.length === 0) {
        try {
          translatedQuestions = await translateArray(questions, language);
        } catch {}
      }
      let translatedTopicQuestionsMap = {};
      for (let i = 0; i < translatedTopics.length; i++) {
        translatedTopicQuestionsMap[translatedTopics[i]] =
          translatedQuestions[i];
      }
      setTopics([...translatedTopics]);
      setTopicQuestionsMap({
        ...translatedTopicQuestionsMap,
      });
      setTopicLoading(false);
      setLoader("hcp__loader--disabled");
    };
    getTopicQuestions();
  }, [language]);

  return (
    <div>
      <Router>
        <Nav
          open={open}
          navModalOpen={navModalOpen}
          close={close}
          totalTopicsInDB={totalTopicsInDB}
          totalQuestionsInDB={totalQuestionsInDB}
          navContent={TranslatedNav[language]}
          recentQuestionsAlert={recentQuestionsAlert}
          setRecentQuestionsAlert={setRecentQuestionsAlert}
        />
        <AnimatePresence
          initial={false}
          mode="wait"
          onExitComplete={() => null}
        >
          {navModalOpen && (
            <NavModal navModalOpen={navModalOpen} handleClose={close} />
          )}
        </AnimatePresence>
        <Routes>
          <Route
            path="/"
            element={
              <Home
                topics={topics}
                topicQuestionsMap={topicQuestionsMap}
                length={topics.length}
                questionField={questionField}
                setQuestion={setQuestion}
                submitButton={submitButton}
                setSubmitButton={setSubmitButton}
                clearButton={clearButton}
                setClearButton={setClearButton}
                answerField={answerField}
                setAnswer={setAnswer}
                visitors={visitors}
                socketId={socketId}
                value={value}
                setValue={setValue}
                topicLoading={topicLoading}
                loader={loader}
                shareButton={shareButton}
                setShareButton={setShareButton}
                audioFile={audioFile}
                setAudioFile={setAudioFile}
                player={player}
                setPlayer={setPlayer}
                audioLoading={audioLoading}
                setAudioLoading={setAudioLoading}
                language={language}
                setLanguage={setLanguage}
                selectRegion={selectRegion}
                setSelectedRegion={setSelectedRegion}
                languageModalOpen={languageModalOpen}
                setLanguageModalOpen={setLanguageModalOpen}
                greet={greet}
                setGreet={setGreet}
                homeContent={TranslatedHome[language]}
                socket={socket}
              />
            }
          ></Route>
          <Route
            path="/faqs"
            element={<Faqs faqsContent={TranslatedFaqs[language]} />}
          ></Route>
          <Route
            path="/recently-asked-questions"
            element={
              <Recentqs
                setQuestion={setQuestion}
                submitButton={submitButton}
                setSubmitButton={setSubmitButton}
                clearButton={clearButton}
                setClearButton={setClearButton}
                setAnswer={setAnswer}
                recentQsContent={TranslatedRecentlyAsked[language]}
                language={language}
              />
            }
          ></Route>
          <Route
            path="/stats"
            element={
              <Stats
                totalQuestions={totalQuestions}
                visitors={visitors}
                questionsRated={questionsRated}
                avgRating={avgRating}
                avgQuestionsPerVisitor={avgQuestionsPerVisitor}
                totalVisitors={totalVisitors}
                totalQuestionsInDB={totalQuestionsInDB}
                totalTopicsInDB={totalTopicsInDB}
                visitorsYesterday={visitorsYesterday}
                visitorsToday={visitorsToday}
                statsContent={TranslatedStats[language]}
                languageCounters={languageCounters}
              />
            }
          ></Route>
          <Route
            path="/create-your-own-digital-avatar"
            element={<CreateYourAvatar />}
          ></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
